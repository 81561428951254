import {UIConfig, FormSection} from "@uplan/common";
import {AnyAction} from "redux";
import {
  CLOSE_INPUT_FRANCHISE,
  OPEN_INPUT_FRANCHISE,
  CLOSE_INPUT_SELECTION,
  OPEN_INPUT_SELECTION,
  DISPLAY_FORM_SECTIONS,
  ERROR,
  ERROR_ACKNOWLEDGED,
  LOAD_UI_CONFIG_REQUEST,
  LOAD_UI_CONFIG_RESPONSE,
  LOAD_CHILD_SELECTION,
  TOGGLE_NAV_DRAWER,
  APP_NAME_CHANGE,
} from "./types";

export interface UIStore {
  uiConfig: UIConfig;
  displayedFormSections?: FormSection;
  isSelectionInputOpen: boolean;
  isFranchiseInputOpen: boolean;
  isFetching: boolean;
  navDrawerOpen: boolean;
  errorMessageKey?: string;
  errorDetails?: string;
  loadChildSelection?: any;
  appName?: string;
}

const initialState = {
  isFetching: false,
  isSelectionInputOpen: false,
  isFranchiseInputOpen: false,
  navDrawerOpen: false,
  uiConfig: {
    childrenMenus: [],
    id: "1",
    sourceRowId: 0,
    sourceSheetId: 1,
    version: 1,
  },
};

export const uiStore = (state: UIStore = initialState, action: AnyAction) => {
  switch (action.type) {
    case CLOSE_INPUT_SELECTION:
      return Object.freeze({
        ...state,
        isSelectionInputOpen: false,
      });
    case OPEN_INPUT_SELECTION:
      return Object.freeze({
        ...state,
        isSelectionInputOpen: true,
      });
    case CLOSE_INPUT_FRANCHISE:
      return Object.freeze({
        ...state,
        isFranchiseInputOpen: false,
      });
    case OPEN_INPUT_FRANCHISE:
      return Object.freeze({
        ...state,
        isFranchiseInputOpen: true,
      });
    case DISPLAY_FORM_SECTIONS:
      return Object.freeze({
        ...state,
        displayedFormSections: action.displayedFormSections,
      });
    case LOAD_UI_CONFIG_REQUEST:
      return Object.freeze({
        ...state,
        isFetching: true,
      });
    case LOAD_UI_CONFIG_RESPONSE:
      return Object.freeze({
        ...state,
        isFetching: false,
        uiConfig: action.uiConfig,
      });
    case LOAD_CHILD_SELECTION:
      return Object.freeze({
        ...state,
        loadChildSelection: action.menuConfig,
      });
    case ERROR:
      return Object.freeze({
        ...state,
        isFetching: false,
        errorDetails: action.details,
        errorMessageKey: action.messageKey,
      });
    case ERROR_ACKNOWLEDGED:
      return Object.freeze({
        ...state,
        errorDetails: undefined,
        errorMessageKey: undefined,
        isFetching: false,
      });
    case TOGGLE_NAV_DRAWER:
      return Object.freeze({
        ...state,
        navDrawerOpen: !state.navDrawerOpen,
      });
    case APP_NAME_CHANGE:
    return Object.freeze({
        ...state,
        appName: action.appName
    });
    default:
      return state;
  }
};
