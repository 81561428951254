import {
  createEntitiesByNameAndId,
  CreateProjectRecordResponse,
  EntitiesResponse,
  EntityValueSelection,
  ProjectEntity,
} from "@uplan/common";
import {AnyAction} from "redux";
import {
  CREATE_FRANCHISE_REQUEST,
  CREATE_FRANCHISE_RESPONSE,
  CREATE_PROJECT_RECORD_REQUEST,
  CREATE_PROJECT_RECORD_RESPONSE,
  FETCH_ENTITIES_REQUEST,
  FETCH_ENTITIES_RESPONSE, RETIRE_NEWEST_FRANCHISE,
  SET_ENTITY_VALUE_SELECTION,
  SET_RECORD_ID,
} from "./types";
import {ERROR} from "../ui/types";

export interface BuilderStore {
  entitiesByType: EntitiesResponse;
  createProjectRecordResponse: CreateProjectRecordResponse;
  entitiesById: {[id: string]: ProjectEntity};
  entitiesByName: {[name: string]: ProjectEntity[]};
  selection: EntityValueSelection;
  isFetching: boolean;
  recordProjectId: number;
  newestFranchise?: ProjectEntity;
}

const initialState = {
  entitiesByType: {},
  entitiesByName: {},
  createProjectRecordResponse: {
    selectedRows: [],
    createdRows: [],
  },
  entitiesById: {},
  selection: {},
  isFetching: false,
  recordProjectId: -1,
};

export const builderStore = (state: BuilderStore = initialState, action: AnyAction) => {
  switch (action.type) {
    case CREATE_FRANCHISE_REQUEST:
    case FETCH_ENTITIES_REQUEST:
      return Object.freeze({
        ...state,
        isFetching: true,
      });
    case CREATE_FRANCHISE_RESPONSE:
      const franchise = {...action.franchise};

      const newFranchiseEntities = state.entitiesByType[franchise.entityType.id].entities.slice();
      newFranchiseEntities.push(franchise);
      const newFranchises = state.entitiesByName[franchise.entityType.name].slice();
      newFranchises.push(franchise);
      const newEntitiesByName = {...state.entitiesByName, [franchise.entityType.name]: newFranchises};
      const newEntitiesById = {...state.entitiesById, [franchise.id]: franchise};
      const newEntitiesByType = {
        ...state.entitiesByType,
        [franchise.entityType.id]: {
          type: franchise.entityType,
          entities: newFranchiseEntities,
        },
      };
      return Object.freeze({
        ...state,
        newestFranchise: franchise,
        isFetching: false,
        entitiesByType: newEntitiesByType,
        entitiesByName: newEntitiesByName,
        entitiesById: newEntitiesById,
      });
    case RETIRE_NEWEST_FRANCHISE:
      return Object.freeze({
       ...state,
       newestFranchise: undefined,
     });
    case FETCH_ENTITIES_RESPONSE:
      const {entitiesById, entitiesByName} = createEntitiesByNameAndId(action.entitiesByType);
      return Object.freeze({
        ...state,
        isFetching: false,
        entitiesByType: action.entitiesByType,
        entitiesByName,
        entitiesById,
      });
    case SET_RECORD_ID:
      return Object.freeze({
        ...state,
        recordProjectId: action.recordProjectId,
      });
    case CREATE_PROJECT_RECORD_REQUEST:
      return Object.freeze({
        ...state,
        isFetching: true,
      });
    case CREATE_PROJECT_RECORD_RESPONSE:
      return Object.freeze({
        ...state,
        createProjectRecordResponse: action.projectRecordResponse,
        isFetching: false,
      });
    case ERROR:
      return Object.freeze({
        ...state,
        isFetching: false,
      });
    case SET_ENTITY_VALUE_SELECTION:
      return Object.freeze({
        ...state,
        selection: action.selectedEntityValue,
      });
    default:
      return state;
  }
};
