import {withStyles, WithStyles} from "@material-ui/core/styles";
import * as React from "react";
import {getToken} from "../../store/auth/actions";
import {ReduxState} from "../../store/ReduxState";
import {displayFormSections, loadUIConfig, toggleNavDrawer} from "../../store/ui/actions";
import {UIStore} from "../../store/ui/reducers";
import {styles} from "../theme";
import NavMenu from "./NavMenu";
import CategoryOptions from "./CategoryOptions";
import {connect, InferableComponentEnhancerWithProps} from "react-redux";
import {fetchEntities} from "../../store/builder/actions";
import RowCreationSummaryDisplay from "./RowCreationSummaryDisplay";

interface LayoutProps {
  uiStore: UIStore;
  loadUIConfig: typeof loadUIConfig;
  fetchEntities: typeof fetchEntities;
  toggleNavDrawer: typeof toggleNavDrawer;
  getToken: typeof getToken;
}

interface LayoutState {
  didRender: boolean;
  drawerWidth: number;
}

class Layout extends React.Component<WithStyles<typeof styles> & LayoutProps, LayoutState> {

  public state = {
    didRender: false,
    drawerWidth: 250
  };

  public componentDidMount = () => {
    if (!this.state.didRender) {
      const {loadUIConfig: loadUIConfigThunk, fetchEntities: fetchEntitiesThunk} = this.props;
      loadUIConfigThunk();
      fetchEntitiesThunk();
    }
  }


  public render() {
    const { uiStore, classes } = this.props;
    return <React.Fragment>
            <NavMenu onDrawerClose={this.onDrawerClose}
                    navDrawerOpen={uiStore.navDrawerOpen}
                    uiConfig={uiStore.uiConfig}
                    onResizeDrawer={this.onResizeDrawer}
                    loadChildSelection={uiStore.loadChildSelection}/>
            <div className={classes.uplanContent}>
              <CategoryOptions loadChildSelection={uiStore.loadChildSelection}/>
              <div className={classes.rowCreationSummaryDisplay}>
                <RowCreationSummaryDisplay rowType={"SELECTED"} title={"Selected Config Rows"}/>
                <RowCreationSummaryDisplay rowType={"CREATED"} title="New Base Sheet Rows"/>
              </div>
            </div>
            </React.Fragment>;
  }

  public onResizeDrawer = (bounds: any) => {
    this.setState({drawerWidth: bounds.width});
  }

  public onDrawerClose = () => {
    this.props.toggleNavDrawer()
  };

}

function mapStateToProps(state: ReduxState) {
  const { uiStore } = state;

  return {
    uiStore,
  };
}

const styledLayoutThunk = withStyles(styles);
const styledLayoutHOC = styledLayoutThunk(Layout);
const reduxConnectedThunk: InferableComponentEnhancerWithProps<LayoutProps, any> = connect(mapStateToProps, {
      displayFormSections,
      loadUIConfig,
      toggleNavDrawer,
      fetchEntities,
});
export default reduxConnectedThunk(styledLayoutHOC);
