import * as React from 'react';

import { withStyles, WithStyles} from "@material-ui/core/styles";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {styles} from "../theme";
import Layout from "./Layout";
import {Paper} from "@material-ui/core";

export class Wizard extends React.Component<WithStyles & InjectedIntlProps> {
  public render() {
    const { classes } = this.props;

    return <Paper className={classes.app}>
      <Layout/>
    </Paper>
  }
}

const intlWizardHOC = injectIntl(Wizard);
const styledWizardThunk = withStyles(styles);
export default styledWizardThunk(intlWizardHOC);
