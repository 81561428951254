import * as React from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect, InferableComponentEnhancerWithProps} from "react-redux";
import {launchCellLinker, loadCellLinkingRules} from "../../store/cellLinker/actions";
import {ReduxState} from "../../store/ReduxState";
import {styles} from "../theme";
import {withStyles, WithStyles} from "@material-ui/core/styles";
import CellLinkerReport from "./CellLinkerReport";
import CheckboxList from "../utrack/CheckboxList";
import SyncJobStatus from "../utrack/SyncJobStatus";

interface CellLinkerProps {
  noProps: boolean;
  loadCellLinkingRules: typeof loadCellLinkingRules;
  launchCellLinker: typeof launchCellLinker;
}

class CellLinker extends React.Component<WithStyles<typeof styles> & CellLinkerProps & InjectedIntlProps> {

  public render() {
    return <div>
         <SyncJobStatus/>
         <CheckboxList/>
         <CellLinkerReport/>
    </div>;
  }


}

function mapStateToProps(state: ReduxState) {
  const { } = state;

  return { /* */ };
}

const intlCellLinkerHOC = injectIntl(CellLinker);
const styledCellLinkerThunk = withStyles(styles);
const styledCellLinkerHOC = styledCellLinkerThunk(intlCellLinkerHOC);
const reduxConnectedThunk: InferableComponentEnhancerWithProps<CellLinkerProps, any> = connect(mapStateToProps, { loadCellLinkingRules, launchCellLinker } );
export default reduxConnectedThunk(styledCellLinkerHOC);

