import {withStyles, WithStyles} from "@material-ui/core/styles";
import {History} from "history";
import * as queryString from "query-string";
import * as React from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect, InferableComponentEnhancerWithProps} from "react-redux";
import {getToken} from "../../store/auth/actions";
import {ReduxState} from "../../store/ReduxState";
import {appNameChange, errorAcknowledged, errorAction, loadUIConfig} from "../../store/ui/actions";
import {styles} from "../theme";
import {AuthStore, USER_TARGET_PATH} from "../../store/auth/reducers";
import {getAuthenticatedUser} from "../../util/auth/Authorization";
import {UIStore} from "../../store/ui/reducers";

interface GetTokenProps {
 history: History;
 authStore: AuthStore;
 getToken: typeof getToken;
 appNameChange: typeof appNameChange;
 uiStore: UIStore;
}

class GetToken extends React.Component<WithStyles<typeof styles> & GetTokenProps & InjectedIntlProps> {

  public componentDidMount() {
      const {getToken: getTokenThunk} = this.props;
      const params = queryString.parse(window.location.search);
      const state = JSON.parse(params.state as string);

      if (getTokenThunk && params.code) {
        getTokenThunk(state.appName as string, params.code as string);
      }
  }

  public componentDidUpdate() {
    const { history, appNameChange: appNameChangeThunk } = this.props;
    const user = getAuthenticatedUser();
    if (user && user.accessToken && user.authorizedApps) {
      // Put the user back where they were when they were logging in
      const userTargetPath = localStorage.getItem(USER_TARGET_PATH);
      if (userTargetPath && userTargetPath !== window.location.pathname) {
        appNameChangeThunk(userTargetPath);
        localStorage.removeItem(USER_TARGET_PATH);
        history.push(userTargetPath);
      }
    }
    return <div/>;
  }

  public render() {
    return <div/>;
  }
}

function mapStateToProps(state: ReduxState) {
  const { uiStore, authStore } = state;

  return {
    uiStore,
    authStore,
  };
}

const intlGetTokenHOC = injectIntl(GetToken);
const styledGetTokenThunk = withStyles(styles);
const styledGetTokenHOC = styledGetTokenThunk(intlGetTokenHOC);
const reduxConnectedThunk: InferableComponentEnhancerWithProps<GetTokenProps, any> =
    connect(mapStateToProps, { appNameChange, errorAcknowledged, errorAction, getToken, loadUIConfig } );
export default reduxConnectedThunk(styledGetTokenHOC);
