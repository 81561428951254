import * as React from "react";
import {ChangeEvent} from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect, InferableComponentEnhancerWithProps} from "react-redux";
import {ReduxState} from "../../store/ReduxState";
import classNames from "classnames";
import {styles} from "../theme";
import {withStyles, WithStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckBox from "@material-ui/icons/CheckBoxSharp";
import Warning from "@material-ui/icons/WarningSharp";
import Error from "@material-ui/icons/ErrorSharp";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Paper,
  Typography,
} from "@material-ui/core";
import {CellLinkResult, LinkerPostResponse, LinkError, LinkingLog, SheetError} from "@uplan/common";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {LinkingDocumentation} from "@uplan/common";

interface CellLinkerReportProps {
  cellLinkerResponse: LinkerPostResponse;
}

interface CellLinkerReportState {
  expanded: {[name: string]: boolean};
}

class CellLinkerReport extends React.Component<WithStyles<typeof styles> & CellLinkerReportProps & InjectedIntlProps, CellLinkerReportState> {

  public state = {
    expanded: {},
  };

  public render() {
    const {cellLinkerResponse, classes, intl} = this.props;
    // const epandeds = ["panel1"];
    // const expanded = epandeds[0];
    if (cellLinkerResponse && cellLinkerResponse.report) {
      const report: LinkingLog = cellLinkerResponse.report;
      return <div>

        {Object.keys(report.linkResultsByRule).map((ruleName, idx) => this.createExpansionPanel(ruleName, report))}
        <Paper className={classes.paperPanel}>

          {report.linkErrors === undefined || report.linkErrors.length === 0 ?
           intl.formatMessage({id: "no_link_errors"}) : intl.formatMessage({id: "link_errors"})}
          {this.getMessageListing(report.linkErrors)}

        </Paper>
        <Paper className={classes.paperPanel}>

          {report.parseErrors === undefined || report.parseErrors.length === 0 ?
           intl.formatMessage({id: "no_parse_errors"}) : intl.formatMessage({id: "parse_errors"})}
          {this.getErrorListing(report.parseErrors)}

        </Paper>
      </div>;
    } else {
      return <div/>;
    }
  }

  public createExpansionPanel(ruleName: string, report: LinkingLog) {
    const {classes, intl} = this.props;
    const resultPayload: {
      results: CellLinkResult[];
      documentation: LinkingDocumentation;
    } = report.linkResultsByRule[ruleName];
    const documentation = resultPayload.documentation;
    const ruleResults = resultPayload.results;
    const linkErrors = report.linkErrors;

    let hasError = false;
    let numberOfLinkErrors = 0;
    const hasLinkError = linkErrors.some((linkError: LinkError): boolean => {
      return linkError.ruleIdentifier === ruleName && linkError.type === "ERROR";
    });
    const hasResultError = ruleResults.some((result: CellLinkResult): boolean => {
      return !!result.linkError && result.linkError.type === "ERROR";
    });
    let hasWarning = false;
    let hasLinkWarning = false;
    if (!hasResultError && !hasLinkError) {
      hasLinkWarning = linkErrors.some((linkError: LinkError): boolean => {
        return linkError.ruleIdentifier === ruleName && linkError.type === "WARNING";
      });
      const hasResultWarning = ruleResults.some((result: CellLinkResult): boolean => {
        return !!result.linkError && result.linkError.type === "WARNING";
      });
      if (hasLinkWarning || hasResultWarning) {
        hasWarning = true;
      }
    } else {
      if (hasResultError) {
        numberOfLinkErrors = ruleResults.filter(result => !!result.linkError && result.linkError.type === "ERROR").length;
        hasError = true;
      }
    }

    const panelClass = hasWarning ? classes.warningPanel : hasError ? classes.errorPanel : classes.successPanel;
    return <ExpansionPanel expanded={this.state.expanded[ruleName]} onChange={this.handleChange(ruleName)} className={panelClass}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
        <Typography className={classes.heading}>{ruleName}</Typography>
        <Typography className={classes.secondaryHeading}>
          {intl.formatMessage({id: ruleResults.length === 1 ? "found_link" : "found_links"}, {linkCount: ruleResults.length})}
        </Typography>
        {hasResultError &&
        <Typography className={classes.secondaryHeading}>
            <b>&nbsp;
            {intl.formatMessage({id: numberOfLinkErrors === 1 ? "number_of_link_error" : "number_of_link_errors"}, {linkErrorCount: numberOfLinkErrors})}
            </b>
        </Typography>
        }
      </ExpansionPanelSummary>
      {hasResultError &&
        <ExpansionPanelDetails>
        <div className={classes.bigColumn}>
          <Typography>
            {this.getResultListing(ruleResults)}
          </Typography>
        </div>
        <div className={classNames(classes.column, classes.columnHelper)}>
          <Typography variant="caption">
            <h4>{intl.formatMessage({id: "description"}, {linkCount: ruleResults.length})}</h4>
            {
              documentation.description
            }
            <br />
          </Typography>
          <Typography variant="caption">
            <h4>{intl.formatMessage({id: "expectation"}, {linkCount: ruleResults.length})}</h4>
            {
              documentation.expectation
            }
            <br />
          </Typography>
          <Typography variant="caption">
            <h4>{intl.formatMessage({id: "explanation"}, {linkCount: ruleResults.length})}</h4>
            {
              documentation.explanation
            }
            <br />
          </Typography>
        </div>
      </ExpansionPanelDetails>
      }
      {hasLinkError &&
        <ExpansionPanelDetails>
        <div className={classes.bigColumn}>
          <Typography>
            {this.getMessageListing(linkErrors.filter(linkError => linkError.ruleIdentifier === ruleName && linkError.type === "ERROR"))}
          </Typography>
        </div>
      </ExpansionPanelDetails>
      }
      {hasLinkWarning &&
        <ExpansionPanelDetails>
        <div className={classes.bigColumn}>
          <Typography>
            {this.getMessageListing(linkErrors.filter(linkError => linkError.ruleIdentifier === ruleName && linkError.type === "WARNING"))}
          </Typography>
        </div>
      </ExpansionPanelDetails>
      }

    </ExpansionPanel>;
  }

  public getResultListing(ruleResults: CellLinkResult[]) {
    const { classes, cellLinkerResponse, intl } = this.props;
    const resultDisplay = ruleResults.map((ruleResult: CellLinkResult) => {
      const dryRun = `[${intl.formatMessage({id: "dry_run"})}]`;
      const status = (`${cellLinkerResponse.dryRun ? dryRun : ""} `) +
                     (ruleResult.linkError ? JSON.stringify(ruleResult.linkError.message) : intl.formatMessage({id: "success"}));
      const key = `${ruleResult.inSheetName}:${ruleResult.inColumnName}:${ruleResult.inRowNumber}` +
                  `${ruleResult.outSheetName}:${ruleResult.outColumnName}:${ruleResult.outRowNumber}`;
      const linkIn = <a className={classes.href} target="_blank" href={ruleResult.inRowUrl}>
                         {`'${ruleResult.inSheetName}' '${ruleResult.inColumnName}' row ${ruleResult.inRowNumber}`}
                       </a>;
      const linkOut = <a className={classes.href} target="_blank" href={ruleResult.outRowUrl}>
                          {`'${ruleResult.outSheetName}' '${ruleResult.outColumnName}' row ${ruleResult.outRowNumber}`}
                        </a>;
      return <Paper className={classes.paperPanel} key={key}>
        <h4> {linkOut} {intl.formatMessage({id: "links_into"})} {linkIn}</h4>
        <div className={classes.panelBody}>
          <ul>
            {ruleResult.reasons.map((reason) => <li key={reason}>{reason}</li>)}
          </ul>
          { intl.formatMessage({id : "Status"}) }: {status}
        </div>
      </Paper>;
    });
    return resultDisplay;
  }

  public handleChange = (name: string) => (event: ChangeEvent<{}>, expanded: boolean) => {
      this.setState({expanded: {...this.state.expanded, name: !this.state.expanded[name]}});
  }

  private getErrorListing(messages: SheetError[]) {
    const { classes } = this.props;
    return <ul> {
      messages.map((error: SheetError, idx) => {
        const link = <a target="_blank" href={error.rowUrl}>{error.sheetName}: {error.cellValue}</a>;
        return <li key={idx}>{link}<pre className={classes.preWrap}>{error.message}</pre></li>;
      })
    }
    </ul>;
  }

  private getMessageListing(messages: LinkError[]) {
    const { classes } = this.props;
    return <List dense={true} className={classes.root + " " + classes.linkErrors}> {
      messages.map((error: LinkError, idx: number) => {
        return <ListItem className={classes.listItemLinkErrors} key={idx}>
          <ListItemIcon>
            {((error.type === "ERROR") ? <Error/> : ((error.type === "WARNING") ? <Warning/> : <CheckBox/>))}
          </ListItemIcon>
          <pre className={classes.preWrap}>{error.message}</pre>
        </ListItem>;
      })
    }
    </List>;
  }
}

function mapStateToProps(state: ReduxState) {
  const { cellLinkerStore } = state;

  return {
    cellLinkerResponse: cellLinkerStore.cellLinkerResponse,
  };
}

const intlCellLinkerReportHOC = injectIntl(CellLinkerReport);
const styledCellLinkerReportThunk = withStyles(styles);
const styledCellLinkerReportHOC = styledCellLinkerReportThunk(intlCellLinkerReportHOC);
const reduxConnectedThunk: InferableComponentEnhancerWithProps<CellLinkerReportProps, any> =
    connect(mapStateToProps, { /* */ } );
export default reduxConnectedThunk(styledCellLinkerReportHOC);
