import {Dispatch} from "redux";
import {
  CREATE_FRANCHISE_REQUEST,
  CREATE_FRANCHISE_RESPONSE,
  CREATE_PROJECT_RECORD_REQUEST,
  CREATE_PROJECT_RECORD_RESPONSE,
  FETCH_ENTITIES_REQUEST,
  FETCH_ENTITIES_RESPONSE, RETIRE_NEWEST_FRANCHISE,
  SET_ENTITY_VALUE_SELECTION,
  SET_RECORD_ID,
} from "./types";
import {wrappedFetch} from "../../util/wrappedFetch";
import {errorAction} from "../ui/actions";
import {ReduxState} from "../ReduxState";
import {config} from "../../config";
import {
  CreateFranchiseRequest,
  CreateFranchiseResponse,
  CreateProjectRecordRequest,
  CreateProjectRecordResponse,
  EntityValueSelection,
  JobStatusResponse,
  RequestError,
  TriggerJobRequest,
  TriggerJobResponse,
} from "@uplan/common";
import {JOB_STATUS_URL, JOB_URL} from "../cellLinker/actions";
import * as pako from "pako";
import {CLOSE_INPUT_FRANCHISE} from "../ui/types";

const ENTITIES_ENDPOINT = `${config.apiBase}entities`;
const FRANCHISE_ENDPOINT = `${config.apiBase}franchise`;

/**
 * Replaces all the entity values in the store with the ones specified here
 */
export const setEntityValueSelection = (selectedEntityValue: EntityValueSelection)  => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ENTITY_VALUE_SELECTION,
    selectedEntityValue,
  });
};

/**
 * Indicates that franchise interactions have actively ocurred so the franchise
 * that had been most recently created isn't really considered important anymore
 */
export const retireNewestFranchise = ()  => (dispatch: Dispatch) => {
  dispatch({
    type: RETIRE_NEWEST_FRANCHISE,
  });
};

export const setRecordId = (recordId: number) => async (dispatch: Dispatch, getState: () => ReduxState) => {
  dispatch({recordId, type: SET_RECORD_ID});
};

export const fetchEntities = () => async (dispatch: Dispatch, getState: () => ReduxState) => {
  try {
    // Seems like typesafe actions would be a good idea.
    dispatch({type: FETCH_ENTITIES_REQUEST});

    const entitiesResponse = await wrappedFetch(`${ENTITIES_ENDPOINT}`);

    const entitiesByType = await entitiesResponse.json();
    dispatch({
               type: FETCH_ENTITIES_RESPONSE,
               entitiesByType,
             });
  } catch (e) {
    errorAction("error_fetching_project_entities", e.message)(dispatch);
  }
};

export const createFranchise = (createRequest: CreateFranchiseRequest) => async (dispatch: Dispatch, getState: () => ReduxState) => {
  try {
    // Seems like typesafe actions would be a good idea.
    dispatch({type: CREATE_FRANCHISE_REQUEST});

    const response = await wrappedFetch(`${FRANCHISE_ENDPOINT}`, {
      method: "POST",
      body: JSON.stringify(createRequest),
    });

    const createFranchiseResponse: CreateFranchiseResponse  = await response.json();
    dispatch({
      type: CLOSE_INPUT_FRANCHISE,
    });
    dispatch({
               type: CREATE_FRANCHISE_RESPONSE,
               franchise: createFranchiseResponse.franchise,
             });
  } catch (e) {
    errorAction("error_fetching_project_entities", e.message)(dispatch);
  }
};

export const createProjectRecord = (dryRun: boolean) => async (dispatch: Dispatch, getState: () => ReduxState) => {
  try {
    dispatch({type: CREATE_PROJECT_RECORD_REQUEST});

    const selection = getState().builderStore.selection;
    const compressedSelection = pako.deflate(JSON.stringify(selection), { to: "string" });

    const payload: CreateProjectRecordRequest =  {
      compressedSelection,
      dryRun,
    };

    const triggerJobRequest: TriggerJobRequest = {
      appName: getState().uiStore.appName as string,
      jobType: "ROW_BUILDING",
      payload,
    };

    const triggerJobResponse = await wrappedFetch(`${JOB_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(triggerJobRequest),
    });

    const response: Response = await triggerJobResponse;
    if (response.status > 299) {
      const uiConfigError: RequestError = await response.json();
      errorAction("error_submitting_project_record", uiConfigError.message)(dispatch);
    } else {
      const jobResponse: TriggerJobResponse = await triggerJobResponse.json();
      const jobId = jobResponse.jobId;
      const intervalId = setInterval(async () => {
        const jobStatusResponse = await wrappedFetch(`${JOB_STATUS_URL}/${jobId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (jobStatusResponse.status > 299) {
          const statusError: RequestError = await jobStatusResponse.json();
          errorAction("error_submitting_project_record", statusError.message)(dispatch);
          clearInterval(intervalId);
        } else {
          const statusResponse: JobStatusResponse = await jobStatusResponse.json();
          if (statusResponse.isComplete && statusResponse.payload) {
            clearInterval(intervalId);
            dispatch({
                       type: CREATE_PROJECT_RECORD_RESPONSE,
                       projectRecordResponse: statusResponse.payload as CreateProjectRecordResponse,
                     });
          } else {
            console.log("Project was created");
          }
        }

      }, 5000);
    }
  } catch (e) {
    errorAction("error_submitting_project_record", e.message)(dispatch);
  }
};
