import * as React from "react";
import {USER_TARGET_PATH} from "../../store/auth/reducers";
import {authenticate} from "../../store/auth/actions";
import {History} from "history";
import {User} from "@uplan/common";
import {getAppNameFromUrl} from "../../store/ui/actions";

export const AUTHENTICATED_USER_KEY = "authenticatedUser";

export const Authorization = (
    history: History,
    authenticator: typeof authenticate,
    onNotAuthorized: () => void) => (appName: string) => (WrappedComponent: any, path: string) => {
  return class WithAuthorization extends React.Component {

    constructor(props: any) {
      super(props);
    }

    // Testing TODO. Test that token refreshing works
    // Testing TODO. On unauthorized clear local state
    public render = () => {

      const user = getAuthenticatedUser();
      if (!user) {
        // The user needs to login.  This will invoke the OAuth flow and redirect
        if (authenticator) {
          localStorage.setItem(USER_TARGET_PATH, path as string);
          authenticator(getAppNameFromUrl(path));
        }
      } else if (user.accessToken && user.authorizedApps) {
        if (user.authorizedApps.indexOf(appName) >= 0) {
          // User is authorized so everything's good to go.
          localStorage.setItem(USER_TARGET_PATH, path as string);
          return <WrappedComponent/>;
        } else {
          onNotAuthorized();
        }
      }
      return <div/>;
    }
  };
};

export const removeAuthenticatedUser = () => {
  localStorage.removeItem(AUTHENTICATED_USER_KEY);
  localStorage.removeItem(USER_TARGET_PATH);
  window.location.href = "/";
};

export const getAuthenticatedUser = () => {

  try {
    const userJSON = localStorage.getItem(AUTHENTICATED_USER_KEY);
    if (userJSON) {
      const storedUser: User = JSON.parse(userJSON) as User;
      return storedUser;
    } else {
      return null;
    }
  } catch (e) {
    removeAuthenticatedUser();
    throw e;
  }
};

//export const setAuthenticatedUser1 = () => {
//  localStorage.setItem("authenticatedUser","{\"id\":6483909157382020,\"email\":\"vignesh.m@nbcuni.com\",\"locale\":\"en_US\",\"timeZone\":\"Asia/Calcutta\",\"alternateEmails\":[],\"title\":\"\",\"department\":\"\",\"company\":\"\",\"workPhone\":\"\",\"mobilePhone\":\"\",\"role\":\"\",\"accessToken\":\"jjxh80hsr5k6inw3sxf5j91od3\",\"refreshToken\":\"jjxh80hsr5k6inw3sxf5j91od3\",\"tokenExpiry\":\"2021-03-15\",\"authorizedApps\":[\"utrack\"]}");
//};

export const setAuthenticatedUser = (user: User) => {
  localStorage.setItem(AUTHENTICATED_USER_KEY, JSON.stringify(user));
};
