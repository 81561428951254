const namespace = "UPLAN_WIZARD";
export const AUTHENTICATION_REQUEST = `${namespace}@AUTHENTICATION_REQUEST`;
export const AUTHENTICATION_RESPONSE = `${namespace}@AUTHENTICATION_RESPONSE`;
export const TOKEN_REQUEST = `${namespace}@TOKEN_REQUEST`;
export const TOKEN_RESPONSE = `${namespace}@TOKEN_RESPONSE`;
export const AUTHENTICATION_ERROR = `${namespace}@AUTHENTICATION_ERROR`;

export const REVOKE_TOKEN_REQUEST = `${namespace}@REVOKE_TOKEN_REQUEST`;
export const REVOKE_TOKEN_RESPONSE = `${namespace}@REVOKE`;

export const REFRESH_TOKEN_REQUEST = `${namespace}@REFRESH_TOKEN_REQUEST`;
export const REFRESH_TOKEN_RESPONSE = `${namespace}@REFRESH_TOKEN_RESPONSE`;
