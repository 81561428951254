import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import {WithStyles, withStyles} from "@material-ui/core/styles";
import * as React from "react";
import {connect, InferableComponentEnhancerWithProps} from "react-redux";

import {ReduxState} from "../../store/ReduxState";
import {styles} from "../theme";
import {launchCellLinker, loadCellLinkingRules} from "../../store/cellLinker/actions";
import {LinkerPostRequest, RulesetRowsResponse} from "@uplan/common";
import {InjectedIntlProps, injectIntl} from "react-intl";
//import {FormControlLabel} from "@material-ui/core";

interface CheckboxListProps {
  ruleset: RulesetRowsResponse;
  loadCellLinkingRules: typeof loadCellLinkingRules;
  launchCellLinker: typeof launchCellLinker;
  appName: string;
}

interface CheckboxListState {
  linkerRequest: LinkerPostRequest;
  lastAppName?: string;
}

class CheckboxList extends React.Component<WithStyles<typeof styles> & CheckboxListProps & InjectedIntlProps, CheckboxListState> {

  public static getDerivedStateFromProps(props: CheckboxListProps, state: CheckboxListState) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
    if (props.appName !== state.lastAppName) {
      const { loadCellLinkingRules: loadRulesThunk } = props;
      loadRulesThunk();
      return {
        lastAppName: props.appName,
      };
    }
    return null;
  }

  public state = {
    linkerRequest: {
      dryRun: false,
      rulesetRowIds: [],
      rulesetSheetId: this.props.ruleset ? this.props.ruleset.rulesetSheetId : 0,
      appName: this.props.appName,
    },
  };

  public handleToggle = (ruleId: number) => () => {
    const { linkerRequest } = this.state;
    const newRowIds: number[] = linkerRequest.rulesetRowIds.slice();
    const ruleIndex = newRowIds.indexOf(ruleId);

    if (ruleIndex < 0) {
      newRowIds.push(ruleId);
    } else {
      newRowIds.splice(ruleIndex, 1);
    }

    const newLinkerRequest = {
      ...linkerRequest,
    };

    this.setState({
      linkerRequest: {
        ...newLinkerRequest,
        rulesetRowIds: newRowIds,
      },
    });
  }

  public launchCellLinker = () => {
    const { launchCellLinker: linkerThunk } = this.props;
    const request = {
      ...this.state.linkerRequest,
      rulesetSheetId: this.props.ruleset.rulesetSheetId,
    };
    linkerThunk(request);
  }

  public render() {
    const { classes, intl } = this.props;
    const ruleset: RulesetRowsResponse = this.props.ruleset;
    const { linkerRequest } = this.state;
    if (!ruleset) {
      return <div/>;
    } else {
      return (
          <Paper className={classes.paperPanel}>
            <List className={classes.list}>
              {Object.keys(ruleset.rulesetRows).map((ruleName) => {
                const rowIds: number[] = linkerRequest.rulesetRowIds;
                const ruleRowId = ruleset.rulesetRows[ruleName];
                return <ListItem key={ruleName} role={undefined} dense={true} button={true}
                                 onClick={this.handleToggle(ruleRowId)}>
                  <Checkbox
                      checked={rowIds.indexOf(ruleRowId) >= 0}
                      tabIndex={-1}
                  />
                  <ListItemText primary={ruleName}/>
                </ListItem>;
              })}
          <Button onClick={this.launchCellLinker}>{ intl.formatMessage({id: "launch_linker"}) }</Button>              
            </List>
          </Paper>
      );
    }
  }
}

function mapStateToProps(state: ReduxState) {
  const { cellLinkerStore, uiStore } = state;

  return {
    ruleset: cellLinkerStore.ruleset,
    appName: uiStore.appName,
  };
}

const intlCheckboxListHOC = injectIntl(CheckboxList);
const styledCheckboxListThunk = withStyles(styles);
const styledCheckboxListHOC = styledCheckboxListThunk(intlCheckboxListHOC);
const reduxConnectedThunk: InferableComponentEnhancerWithProps<CheckboxListProps, any> = connect(mapStateToProps, { loadCellLinkingRules, launchCellLinker } );
export default reduxConnectedThunk(styledCheckboxListHOC);
