import {User} from "@uplan/common";
import fetch from "cross-fetch";
import * as moment from "moment";
import {Dispatch} from "redux";
import {REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_RESPONSE} from "../store/auth/types";
import {ReduxState} from "../store/ReduxState";
import {errorAction} from "../store/ui/actions";
import {getAuthenticatedUser, removeAuthenticatedUser} from "./auth/Authorization";

const TOKEN_REFRESH_ENDPOINT = "/refreshToken";

interface FetchWrapping {
  getState: () => ReduxState;
  dispatch: Dispatch;
}

const wrapping: FetchWrapping = {
  dispatch: {} as Dispatch,
  getState: {} as () => ReduxState,
};

export const wrappedFetch = async (url: string, params?: RequestInit) => {
  let user = getAuthenticatedUser();
  const init: RequestInit = params ? params : {};
  if (!init.headers) {
    init.headers = {};
  }

  if (!user) {
    throw new Error("There is no user.");
  }

  if (user.accessToken) {
    if (shouldRefreshToken(user)) {
      user = await refreshAccessToken();
    }

    if (user) {
      const wrappedHeaders = {...init.headers, Authorization: `Bearer: ${user.accessToken}`};
      const appName = wrapping.getState().uiStore.appName;
      const wrappedParams = {...init, headers: wrappedHeaders};
      if (!init.method || init.method.toUpperCase() === "GET") {
        url += `${url.indexOf("?") > 0 ? "&" : "?"}appName=${appName}`;
      } else {
        const currentBody = (typeof init.body === "string") ? JSON.parse(init.body) : init.body ? init.body : {};
        wrappedParams.body =  JSON.stringify({...currentBody, appName});
      }

      const response = await fetch(url, wrappedParams);
      if (response.status === 401 || response.status === 403 || response.status === 407) {
        //  Any security errors will kick the user out
        removeAuthenticatedUser();
      }
      return response;
    } else {
      throw Error("Your session timed out.  You need to login again.");
      setTimeout(() => {
        removeAuthenticatedUser();
        window.location.href = "/";
      }, 10000);
    }
   } else {
    throw Error(`The user has no acces token.  The user must authenticate first ${JSON.stringify(user)}`);
  }
};

export const wrapFetch = (getState: () => ReduxState, dispatch: Dispatch) => {
  wrapping.dispatch = dispatch;
  wrapping.getState = getState;
};

/**
 * Smartsheet tokens expire 7 days after they're issued.
 * If the tokens are refreshed when they've got 5 days left it should leave plenty of time
 * for the tokens to be refreshed as needed
 * @param user
 */
export const shouldRefreshToken = (user: User) => {
  // if (true) {
  if (user.refreshToken && user.tokenExpiry) {
    //@ts-ignore
    const now = moment(new Date());
    //@ts-ignore
    const expiry = moment(user.tokenExpiry);
    // moment will return negative values as expected.
    const duration = expiry.diff(now, "days");
    const fiveDays = 5;
    if (duration < fiveDays) {
      return true;
    } else {
      return false;
    }
  } else {
    throw Error(`The user doesn't have a refresh token and expiry: ${JSON.stringify(user)}`);
  }
};

export const refreshAccessToken = async () => {
  try {
    // Seems like typesafe actions would be a good idea.
    wrapping.dispatch({type: REFRESH_TOKEN_REQUEST});
    const appName = wrapping.getState().uiStore.appName;
    const authenticatedUser = getAuthenticatedUser();
    if (authenticatedUser) {
      const refreshToken = authenticatedUser.refreshToken;

      const init = {
        headers: {
          Authorization: `Bearer: ${authenticatedUser.accessToken}`,
        },
      };

      const authResponse = await fetch(`${TOKEN_REFRESH_ENDPOINT}?refreshToken=${refreshToken}&appName=${appName}`, init);
      const auth = await authResponse.json();
      if (auth.message) {
        errorAction("error_refreshing_token", auth.message)(wrapping.dispatch);
      } else {
        wrapping.dispatch({
          type: REFRESH_TOKEN_RESPONSE,
          user: auth,
        });
        return auth;
      }
    }
  } catch (e) {
    errorAction("error_refreshing_token", e.message)(wrapping.dispatch);
  }
  return null;
};
