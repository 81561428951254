const namespace = "UPLAN_WIZARD";

export const SET_ENTITY_VALUE_SELECTION = `${namespace}@SET_ENTITY_VALUE_SELECTION`;

export const CREATE_PROJECT_RECORD_REQUEST = `${namespace}@CREATE_PROJECT_RECORD_REQUEST`;
export const CREATE_PROJECT_RECORD_RESPONSE = `${namespace}@CREATE_PROJECT_RECORD_RESPONSE`;
export const SET_RECORD_ID = `${namespace}@SET_RECORD_ID`;

export const FETCH_ENTITIES_REQUEST = `${namespace}@FETCH_ENTITIES_REQUEST`;
export const FETCH_ENTITIES_RESPONSE = `${namespace}@FETCH_ENTITIES_RESPONSE`;
export const CREATE_FRANCHISE_REQUEST = `${namespace}@CREATE_FRANCHISE_REQUEST`;
export const CREATE_FRANCHISE_RESPONSE = `${namespace}@CREATE_FRANCHISE_RESPONSE`;
export const RETIRE_NEWEST_FRANCHISE = `${namespace}@RETIRE_NEWEST_FRANCHISE`;
