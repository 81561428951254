import * as React from "react";

import {withStyles, WithStyles} from "@material-ui/core/styles";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {styles} from "../theme";
// @ts-ignore
import Center from "react-center";
import Fab from "@material-ui/core/Fab";
import uTrackLogo from "../../assets/uTrackLogo.png";
import Divider from "@material-ui/core/Divider";
import uPlanLogo from "../../assets/uPlanLogo.png";
import logo from "../../assets/NBCULogo.png";
import {authenticate} from "../../store/auth/actions";
import {AuthStore} from "../../store/auth/reducers";
import {connect} from "react-redux";
import {ReduxState} from "../../store/ReduxState";
import {History} from "history";
import {appNameChange} from "../../store/ui/actions";

import {UPLAN_WIZARD_LINK, UTRACK_CELL_LINKER_LINK} from "../../App";
import {config} from "../../config";
import {UPLAN_APP, UTRACK_APP} from "@uplan/common";

interface OwnProps {
  authStore: AuthStore;
  history: History;
}

type Props = StateProps & DispatchProps & OwnProps;
/**
 */
class Home extends React.Component<WithStyles & InjectedIntlProps & Props> {
  public render() {
    const { classes, intl } = this.props;

    console.log(`CONFIG IS ${JSON.stringify(config, null, 2)}`);
    const showUplan = !config.appName || config.appName === UPLAN_APP;
    const showUtrack = !config.appName || config.appName === UTRACK_APP;

    return <div className={classes.app}>
      <header className={classes.appHeader}>
        <h1 className={classes.appLogo}>{ intl.formatMessage({id: "getting_started_instructions"}) }</h1>
      </header>
      <Center>
        <div className={classes.bigButtonWrapper}>
          <Center>
            {showUtrack ?
              <Fab variant="extended" className={classes.landingButton}
                   onClick={this.handleAppSelection(UTRACK_CELL_LINKER_LINK)}>
                <img src={uTrackLogo} className={classes.appLogo} alt="logo"/>
              </Fab>
             : null
            }
          </Center>
          <Center>
            <Divider/>
            {showUplan ?
              <Fab variant="extended" className={classes.landingButton}
                   onClick={this.handleAppSelection(UPLAN_WIZARD_LINK)}>
                <img src={uPlanLogo} className={classes.appLogo} alt="logo"/>
              </Fab>
             : null
            }
          </Center>
        </div>
        <img src={logo} className={classes.introLogo} alt="logo"/>
      </Center>
    </div>;
  }

  private handleAppSelection = (destinationPath: string) => () => {
    const { history, appNameChange: appNameChangeThunk } = this.props;
    history.push(destinationPath);
    appNameChangeThunk();
  }
}

interface StateProps {
  authStore: AuthStore;
}

function mapStateToProps(state: ReduxState) {
  const { authStore } = state;

  return {
    authStore,
  };
}

interface DispatchProps {
  authenticate: typeof authenticate;
  appNameChange: typeof appNameChange;
}

const intlHomeHOC = injectIntl(Home);
const styledHomeThunk = withStyles(styles);
// @ts-ignore
export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, {authenticate, appNameChange})<any>(styledHomeThunk(intlHomeHOC));
