const namespace = "UPLAN_WIZARD";


export const CLOSE_INPUT_FRANCHISE = `${namespace}@CLOSE_INPUT_FRANCHISE`;
export const OPEN_INPUT_FRANCHISE = `${namespace}@OPEN_INPUT_FRANCHISE`;
export const CLOSE_INPUT_SELECTION = `${namespace}@CLOSE_INPUT_SELECTION`;
export const OPEN_INPUT_SELECTION = `${namespace}@OPEN_INPUT_SELECTION`;
export const TOGGLE_NAV_DRAWER = `${namespace}@TOGGLE_NAV_DRAWER`;
export const ERROR = `${namespace}@ERROR`;
export const ERROR_ACKNOWLEDGED = `${namespace}@ERROR_ACKNOWLEDGED`;
export const LOAD_UI_CONFIG_REQUEST = `${namespace}@LOAD_UI_CONFIG_REQUEST`;
export const LOAD_UI_CONFIG_RESPONSE = `${namespace}@LOAD_UI_CONFIG_RESPONSE`;
export const LOAD_CHILD_SELECTION = `${namespace}@LOAD_CHILD_SELECTION`;
export const APP_NAME_CHANGE = `${namespace}@APP_NAME_CHANGE`
export const DISPLAY_FORM_SECTIONS = `${namespace}@DISPLAY_FORM_SECTIONS`;
