import {
  FormSection,
  JobStatusResponse,
  Menu,
  RequestError,
  TriggerJobRequest,
  TriggerJobResponse,
  UIConfig,
  UIConfigRequest,
  UPLAN_APP,
  UTRACK_APP,
} from "@uplan/common";
import {wrappedFetch} from "../../util/wrappedFetch";
import {Dispatch} from "redux";
import {
  APP_NAME_CHANGE,
  CLOSE_INPUT_FRANCHISE,
  CLOSE_INPUT_SELECTION,
  DISPLAY_FORM_SECTIONS,
  ERROR,
  ERROR_ACKNOWLEDGED,
  LOAD_CHILD_SELECTION,
  LOAD_UI_CONFIG_REQUEST,
  LOAD_UI_CONFIG_RESPONSE,
  OPEN_INPUT_FRANCHISE,
  OPEN_INPUT_SELECTION,
  TOGGLE_NAV_DRAWER,
} from "./types";
import {config} from "../../config";
import {JOB_STATUS_URL, JOB_URL} from "../cellLinker/actions";
import {ReduxState} from "../ReduxState";

// Seems like having endpoint constants defined in a common constant file
// would be a good idea
const UI_CONFIG_ENDPOINT = `${config.apiBase}uiConfig`;
const TOKEN_FETCH = "token";

/**
 * Action indicating the nav drawer should close
 */
export const toggleNavDrawer = () => ({
  type: TOGGLE_NAV_DRAWER,
});

export const errorAcknowledged = () => (dispatch: Dispatch) => {
  dispatch({
    type: ERROR_ACKNOWLEDGED,
  });
};

export const displayFormSections = (displayedFormSections: FormSection[]) => (dispatch: Dispatch) => {
  dispatch({
    type: DISPLAY_FORM_SECTIONS,
    displayedFormSections,
  });
};

export const loadChildSelection = (menuConfig: Menu) => (dispatch: Dispatch) => {
    dispatch({
      type: LOAD_CHILD_SELECTION,
      menuConfig,
    });
};

export const closeInputFranchise = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLOSE_INPUT_FRANCHISE,
  });
};

export const openInputFranchise = () => (dispatch: Dispatch) => {
  dispatch({
    type: OPEN_INPUT_FRANCHISE,
  });
};

export const closeInputSelection = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLOSE_INPUT_SELECTION,
  });
};

export const openInputSelection = () => (dispatch: Dispatch) => {
  dispatch({
    type: OPEN_INPUT_SELECTION,
  });
};

export const errorAction = (messageKey: string, details: string) => (dispatch: Dispatch) => {
  dispatch({
    details,
    messageKey,
    type: ERROR,
  });
};

export const getAppNameFromUrl = (resourcePath?: string) => {
  if (!resourcePath) {
    resourcePath = window.location.href;
  }
  let applicationName = "";
  if (resourcePath.toLowerCase().indexOf(`/${UPLAN_APP.toLowerCase()}/`) >= 0) {
    applicationName = UPLAN_APP;
  } else if (resourcePath.toLowerCase().indexOf(`/${UTRACK_APP.toLowerCase()}/`) >= 0) {
    applicationName = UTRACK_APP;
  } else if (resourcePath.toLowerCase().indexOf("token") >= 0) {
    applicationName = TOKEN_FETCH;
  } else {
    applicationName = "";
  }
  return applicationName;
};

export const appNameChange = (resourcePath?: string) => (dispatch: Dispatch) => {
  const nameFromUrl = getAppNameFromUrl(resourcePath);
  // This is a part of the app flow where control is directed to
  // another page then back into the app.  The nameFromUrl needs to be
  // protected during this flow
  if (nameFromUrl !== TOKEN_FETCH) {
    document.title = config.appEnv !== "production" ? `${config.appTitle} ${config.appEnv}` : config.appTitle;
    dispatch({
      type: APP_NAME_CHANGE,
      appName: nameFromUrl,
    });
  }
};

export const ingestUIConfig = () => async (dispatch: Dispatch, getState: () => ReduxState) => {
  try {
    dispatch({type: LOAD_UI_CONFIG_REQUEST});

    const triggerJobRequest: TriggerJobRequest = {
      appName: getState().uiStore.appName as string,
      jobType: "UI_CONFIG",
      payload: {} as UIConfigRequest,
    };

    const triggerJobResponse = await wrappedFetch(`${JOB_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(triggerJobRequest),
    });

    const response: Response = await triggerJobResponse;
    if (response.status > 299) {
      const uiConfigError: RequestError = await response.json();
      errorAction("error_loading_ui_config", uiConfigError.message)(dispatch);
    } else {
      const jobResponse: TriggerJobResponse = await triggerJobResponse.json();
      const jobId = jobResponse.jobId;
      const intervalId = setInterval(async () => {
        const jobStatusResponse = await wrappedFetch(`${JOB_STATUS_URL}/${jobId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (jobStatusResponse.status > 299) {
          const statusError: RequestError = await jobStatusResponse.json();
          errorAction("error_loading_ui_config", statusError.message)(dispatch);
          clearInterval(intervalId);
        } else {
          const statusResponse: JobStatusResponse = await jobStatusResponse.json();
          if (statusResponse.isComplete && statusResponse.payload) {
            clearInterval(intervalId);
            dispatch({
                       type: LOAD_UI_CONFIG_RESPONSE,
                       uiConfig: statusResponse.payload as UIConfig,
                     });
          } else {
            console.log("UIConfig was received");
          }
        }

      }, 5000);
    }
  } catch (e) {
    errorAction("error_loading_ui_config", e.message)(dispatch);
  }
};

export const loadUIConfig = () => async (dispatch: Dispatch, getState: () => ReduxState) => {
  try {
    // Seems like typesafe actions would be a good idea.
    dispatch({type: LOAD_UI_CONFIG_REQUEST});

    const uiFetchResponse = await wrappedFetch(`${UI_CONFIG_ENDPOINT}`);
    if (uiFetchResponse.status === 503) {
      // It's permanently gone.  It needs to be recreatd
      await ingestUIConfig()(dispatch, getState);
    } else {
      const uiConfig = await uiFetchResponse.json();
      dispatch({
                 type: LOAD_UI_CONFIG_RESPONSE,
                 uiConfig,
               });
    }
  } catch (e) {
    errorAction("error_loading_ui_config", e.message)(dispatch);
  }
};
