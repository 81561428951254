import {withStyles, WithStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

import {styles} from "../theme";
import {RowCreationSummary} from "@uplan/common";

import {InjectedIntlProps, injectIntl} from "react-intl";
import * as React from "react";
import {connect} from "react-redux";
import {ReduxState} from "../../store/ReduxState";

interface OwnProps {
  rowType: "CREATED" | "SELECTED";
  title: string;
}

type Props = StateProps & OwnProps;

class RowCreationSummaryDisplay extends React.Component<WithStyles<typeof styles> & InjectedIntlProps & Props, {}> {

  public state = {};

  public render = () => {
    const {classes, displayedRows, title, dryRun, rowType} = this.props;
    const baseSheetSections: any[] = [];

    if (displayedRows) {
      this.buildSheetTable(displayedRows, baseSheetSections);
    }

    const warnTest = dryRun && rowType === "CREATED";
    return <div className={classes.selectedRowTable}>
      <List>
      {displayedRows.length > 0 ? <h3>{`${title} ${warnTest ? "(Test Only - No Rows Created)" : ""}`}</h3> : null }
      {baseSheetSections}
      </List>
    </div>;
  }

  private buildSheetTable(selectedRowSummaries: RowCreationSummary[], sections: any[]) {
    const { classes } = this.props;
    for (const summary of selectedRowSummaries) {
      sections.push(
        <ListItem className={classes.selectedRowListItem}>
          <a href={summary.sheetUrl} target="_blank"><ListItemText primary={summary.sheetName}/></a>
        </ListItem>);
    }
  }
}

interface StateProps {
  displayedRows: RowCreationSummary[];
  dryRun: boolean;
}

function mapStateToProps(state: ReduxState, props: OwnProps): StateProps {
  const { builderStore } = state;

  return {
    displayedRows: props.rowType === "CREATED" ?
                 builderStore.createProjectRecordResponse.createdRows :
                 builderStore.createProjectRecordResponse.selectedRows,
    dryRun: !!builderStore.createProjectRecordResponse.dryRun,

  };
}

const intlRowCreationSummaryDisplayHOC = injectIntl(RowCreationSummaryDisplay);
const styledRowCreationSummaryDisplayThunk = withStyles(styles, { withTheme: true });
const styledRowCreationSummaryDisplayHOC = styledRowCreationSummaryDisplayThunk(intlRowCreationSummaryDisplayHOC);

export default connect<StateProps, {}, OwnProps, ReduxState>(mapStateToProps)<any>(styledRowCreationSummaryDisplayThunk(styledRowCreationSummaryDisplayHOC));
