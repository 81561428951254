import {AnyAction} from "redux";
import {
  AUTHENTICATION_ERROR,
  AUTHENTICATION_RESPONSE,
  REFRESH_TOKEN_RESPONSE,
  REVOKE_TOKEN_REQUEST,
  REVOKE_TOKEN_RESPONSE, TOKEN_RESPONSE,
} from "./types";
import {User} from "@uplan/common";
import {ERROR} from "../ui/types";
import {removeAuthenticatedUser, setAuthenticatedUser} from "../../util/auth/Authorization";

export const AUTHENTICATED_USER_KEY = "authenticatedUser";
export const USER_TARGET_PATH = "userTargetPath";

export interface AuthStore {
  authenticatedUser?: User;
}

const initialState = {};

export const authStore = (state: AuthStore = initialState, action: AnyAction) => {
  switch (action.type) {
    case REFRESH_TOKEN_RESPONSE: {
      setAuthenticatedUser(action.user as User);
      return state;
    }
    case TOKEN_RESPONSE:
    case AUTHENTICATION_RESPONSE: {
      return Object.freeze({
        ...state,
        authenticatedUser: {
          ...state.authenticatedUser,
          authenticationCode: action.authenticationCode,
        },
      });
    }
    case REVOKE_TOKEN_REQUEST: {
      return state;
    }
    case ERROR:
      return Object.freeze({
        ...state,
        isFetching: false,
      });
    case AUTHENTICATION_ERROR:
      return Object.freeze({
        ...state,
        authenticatedUser: null,
      });
    case REVOKE_TOKEN_RESPONSE: {
      removeAuthenticatedUser();
      return Object.freeze({
        ...state,
        authenticatedUser: null,
      });
    }

    default:
      return state;
  }
};
