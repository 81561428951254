import {AnyAction} from "redux";
import {
  LAUNCH_CELL_LINKER_REQUEST,
  LAUNCH_CELL_LINKER_RESPONSE,
  RULE_SET_MAP_REQUEST,
  RULE_SET_MAP_RESPONSE,
  SYNC_JOB_REQUEST,
  SYNC_JOB_RESPONSE,
} from "./types";
import {LinkerPostResponse, RulesetRowsResponse} from "@uplan/common";
import {ERROR} from "../ui/types";

export interface CellLinkerStore {
  ruleset?: RulesetRowsResponse;
  isFetching: boolean;
  isSyncing: boolean;
  syncComplete: boolean;
  cellLinkerResponse?: LinkerPostResponse;
}

const initialState = {
  isFetching: false,
  isSyncing: false,
  syncComplete: false,
};

export const cellLinkerStore = (state: CellLinkerStore = initialState, action: AnyAction) => {
  switch (action.type) {
    case ERROR:
      return Object.freeze({
        ...state,
        isFetching: false,
      });
    case RULE_SET_MAP_REQUEST:
    case LAUNCH_CELL_LINKER_REQUEST:
      return Object.freeze({
        ...state,
        isFetching: true,
      });
    case LAUNCH_CELL_LINKER_RESPONSE:
      return Object.freeze({
        ...state,
        isFetching: false,
        cellLinkerResponse: action.linkerResponse,
      });
    case RULE_SET_MAP_RESPONSE:
      return Object.freeze({
        ...state,
        isFetching: false,
        ruleset: action.ruleset,
      });
    case SYNC_JOB_REQUEST:
      return Object.freeze({
        ...state,
        isSyncing: true,
        syncComplete: false,
      });
    case SYNC_JOB_RESPONSE:
      return Object.freeze({
        ...state,
        isSyncing: false,
        syncComplete: true,
      });
    default:
      return state;
  }
};
