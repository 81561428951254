import {ConnectedRouter} from "connected-react-router";
import {History} from "history";
import React from "react";

import {withStyles, WithStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import Home from "./components/home/Home";
import GetToken from "./components/wizard/GetToken";
import Wizard from "./components/wizard/Wizard";
import {ReduxState} from "./store/ReduxState";
// @ts-ignore
import LoadingOverlay from "react-loading-overlay";

import {styles} from "./components/theme";
import CellLinker from "./components/celllinker/CellLinker";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/core/SvgIcon/SvgIcon";
import {errorAcknowledged, errorAction, ingestUIConfig, toggleNavDrawer} from "./store/ui/actions";
import {UIStore} from "./store/ui/reducers";
import {runSyncJob} from "./store/cellLinker/actions";
import {ErrorDialog} from "./components/ErrorDialog";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {AuthStore} from "./store/auth/reducers";
import {Button} from "@material-ui/core";
import {authenticate, revokeToken} from "./store/auth/actions";
import {UPLAN_APP, UTRACK_APP} from "@uplan/common";
import {
  Authorization,
  getAuthenticatedUser,
  removeAuthenticatedUser,
} from "./util/auth/Authorization";
import {createProjectRecord} from "./store/builder/actions";
import {appNameChange} from "./store/ui/actions";
import {config} from "./config";

// These types shouldn't be optional.  The connect method isn't typed properly
interface OwnProps {
  history: History;
}

export const UTRACK_CELL_LINKER_LINK = `/${UTRACK_APP}/cellLinker`;
export const UPLAN_CELL_LINKER_LINK = `/${UPLAN_APP}/cellLinker`;
export const UPLAN_WIZARD_LINK = `/${UPLAN_APP}/wizard`;

interface AppState {
  drawerWidth: number;
}

type Props = OwnProps & DispatchProps & StateProps;

class App extends React.Component<Props & WithStyles & InjectedIntlProps, AppState> {

  public state = {
    drawerWidth: 250,
  };

  private routes: any;

  public componentDidMount(): void {
    const {appNameChange: appNameChangeThunk} = this.props;
    appNameChangeThunk();
  }

  public componentWillMount(): void {
    const {history, authenticate: authenticator} = this.props;
    if (authenticator) {
      const Authorizer = Authorization(history, authenticator, this.onNotAuthorized);
      const AuthorizedWizard = Authorizer(UPLAN_APP)(Wizard, "/uPlan/wizard");
      const AuthorizedUtrackCellLinker = Authorizer(UTRACK_APP)(CellLinker, "/uTrack/cellLinker");
      const AuthorizedUPlanCellLinker = Authorizer(UPLAN_APP)(CellLinker, "/uPlan/cellLinker");
      this.routes = <Switch>
        <Route path="/" exact={true} component={Home}/>
        <Route path="/token" component={GetToken}/>
        <Route path={UPLAN_WIZARD_LINK} component={AuthorizedWizard}/>
        <Route path={UTRACK_CELL_LINKER_LINK} component={AuthorizedUtrackCellLinker}/>
        <Route path={UPLAN_CELL_LINKER_LINK} component={AuthorizedUPlanCellLinker}/>
      </Switch>;
    }
  }

  public render() {

    const { history, intl, uiStore, loading, loadingTextKey, classes } = this.props;
    const user = getAuthenticatedUser();
    let header;
    console.log("Rendering app name with " + uiStore.appName);
    if (user && user.accessToken) {
      const username = `${user.firstName} ${user.lastName}`;

      const ingestButton = uiStore && uiStore.appName === UPLAN_APP ?
      <Button onClick={this.ingestConfig}>
        {intl.formatMessage({ id: "ingest_ui_config" })} ffff
      </Button> : null;

      const syncFormulasButton = uiStore && uiStore.appName === UPLAN_APP ?
      <Button onClick={this.runSync("FORMULA_SYNC")}>
        {intl.formatMessage({ id: "sync_formulas" })}
      </Button> : null;

      const syncTitlePlanButton = uiStore && uiStore.appName === UTRACK_APP ?
      <Button onClick={this.runSync("TITLE_PLAN_SYNC")}>
        {intl.formatMessage({ id: "sync_title_plan" })}
      </Button> : null;

      const syncTitleSummariesButton = uiStore && uiStore.appName === UTRACK_APP ?
      <Button onClick={this.runSync("TITLE_SUMMARY_SYNC")}>
        {intl.formatMessage({ id: "sync_title_summaries" })}
      </Button> : null;
      
      const createMultiTitleLinksButton = uiStore && uiStore.appName === UTRACK_APP ?
      <Button onClick={this.runSync("MULTI-TITLE_MULTI-TITLE_CELL_LINKING")}>
        {intl.formatMessage({ id: "create_multi_title_links" })}
      </Button> : null;

      const cellLinkerButton = uiStore && uiStore.appName === UPLAN_APP ?
      <Button color="inherit" onClick={this.celllinker}>
        {intl.formatMessage({ id: "cell_linker_link_text" })}
      </Button> : null;

      const wizardButton = uiStore && uiStore.appName === UPLAN_APP ?
      <Button color="inherit" onClick={this.wizard}>
        {intl.formatMessage({ id: "wizard_link_text" })}
      </Button> : null;

      // change color of header bar depending on environment
      const appBarElement = document.getElementById('appBar') as HTMLElement;
      if (appBarElement) {
        appBarElement.style.backgroundColor = config.appHeaderColor;
      }

      header = <div>
              <AppBar position="fixed" id="appBar" className={classes.appBar}
                      style={{marginLeft: this.state.drawerWidth}}>
                <Toolbar>
                  <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={this.onDrawerClose}
                      className={classes.menuButton}
                  >
                    <MenuIcon/>
                  </IconButton>
                  <div className={classes.grow} />
                  {cellLinkerButton}
                  {wizardButton}
                  {ingestButton}
                  {syncTitlePlanButton}
                  {syncTitleSummariesButton}
                  {syncFormulasButton}
                  {createMultiTitleLinksButton}
                  <Button color="inherit" onClick={this.logoff}>
                    {intl.formatMessage({ id: "logoff_user" }, {username})}
                  </Button>

                </Toolbar>
              </AppBar>
            </div>;

    }

    return (
          <div className={classes.root}>
            {header}
            <LoadingOverlay
                active={loading}
                spinner={true}
                text={intl.formatMessage({id: loadingTextKey || "key"})}
            />
              <div className={classes.main}>
                <CssBaseline/>
                <ConnectedRouter history={history}>
                  { this.routes }
                </ConnectedRouter>
              </div>
            <ErrorDialog isOpen={!!(uiStore && uiStore.errorMessageKey)}
                         intl={intl}
                         message={intl.formatMessage({id: uiStore && uiStore.errorMessageKey || "nomessage"})}
                         handleClose={this.closeError}
                         details={uiStore && uiStore.errorDetails || ""}/>

          </div>
      );
  }

  public onNotAuthorized = () => {
    const { intl, errorAction: errorActionThunk } = this.props;
    errorActionThunk("not_authorized", intl.formatMessage({id: "not_authorized_details"}));
    setTimeout(() => {
      removeAuthenticatedUser();
      window.location.href = "/";
    }, 5000);
  }

  private ingestConfig = () => {
    const { ingestUIConfig: ingestUIConfigThunk } = this.props;
    ingestUIConfigThunk();
  }

  private runSync = (jobType: "FORMULA_SYNC" | "TITLE_PLAN_SYNC"| "TITLE_SUMMARY_SYNC" | "MULTI-TITLE_MULTI-TITLE_CELL_LINKING") => () => {
    const { runSyncJob: syncThunk } = this.props;
    syncThunk(jobType);
  }

  private logoff = () => {
    const { authStore, history, revokeToken: revokeTokenThunk } = this.props;
    if (!authStore) {
      throw Error("authStore is missing");
    } else {
      if (getAuthenticatedUser()) {
        revokeTokenThunk();
        history.push("/");
      }
    }
  }

  private celllinker = () => {
    const { authStore, history } = this.props;
    if (!authStore) {
      throw Error("authStore is missing");
    } else {
      history.push("/uPlan/cellLinker");
    }
  }

  private wizard = () => {
    const { authStore, history } = this.props;
    if (!authStore) {
      throw Error("authStore is missing");
    } else {
      history.push("/uPlan/wizard");
    }
  }

  private onDrawerClose = () => {
    const {toggleNavDrawer: toggle} = this.props;
    if (toggle) {
      toggle();
    }
  }

  private closeError = () => {
    const {errorAcknowledged: ack} = this.props;
    if (ack) {
      ack();
    }
  }
}

interface StateProps {
  authStore: AuthStore | undefined;
  uiStore: UIStore;
  loadingTextKey: string;
  loading: boolean;
}

function mapStateToProps(state: ReduxState, props: OwnProps): StateProps {
  const { authStore, uiStore, cellLinkerStore, builderStore } = state;

  const loading = uiStore.isFetching || cellLinkerStore.isFetching || builderStore.isFetching || cellLinkerStore.isSyncing;
  let loadingTextKey = "" ;
  if (uiStore.isFetching) {
    loadingTextKey = "retrieving_configuration";
  } else if (cellLinkerStore.isSyncing) {
    loadingTextKey = "syncing";
  } else if (cellLinkerStore.isFetching) {
    loadingTextKey = "linking";
  } else if (builderStore.isFetching) {
    loadingTextKey = "building_rows";
  }

  return {
    authStore,
    uiStore,
    loadingTextKey,
    loading
  };
}

interface DispatchProps {
  appNameChange: typeof appNameChange;
  createProjectRecord: typeof createProjectRecord;
  ingestUIConfig: typeof ingestUIConfig;
  runSyncJob: typeof runSyncJob;
  revokeToken: typeof revokeToken;
  authenticate: typeof authenticate;
  errorAcknowledged: typeof errorAcknowledged;
  errorAction: typeof errorAction;
  toggleNavDrawer: typeof toggleNavDrawer;
}

const mapDispatchToProps = { toggleNavDrawer, errorAction, errorAcknowledged, revokeToken,
  authenticate, ingestUIConfig, runSyncJob, createProjectRecord, appNameChange };

const intlLayoutHOC = injectIntl(App);
const styledAppThunk = withStyles(styles);
// TODO figure out the correct types here and type it properly

export default connect<StateProps, DispatchProps, OwnProps, ReduxState>(mapStateToProps, mapDispatchToProps)<any>(styledAppThunk(intlLayoutHOC));
