// @ts-ignore
const API_BASE = window.API_BASE ? window.API_BASE : "/";
// @ts-ignore
const APP_NAME = window.APP_NAME;
// @ts-ignore
const APP_ENV = window.APP_ENV ? window.APP_ENV : "localhost";
// @ts-ignore
const APP_TITLE = window.APP_TITLE ? window.APP_TITLE : "uPlan and uTrack";
// @ts-ignore
const APP_HEADER_COLOR = window.APP_HEADER_COLOR ? window.APP_HEADER_COLOR : "#aaaaaa";

console.log(`using API_BASE=${API_BASE}`);
export const config = {
  apiBase: API_BASE,
  appName: APP_NAME,
  appEnv: APP_ENV,
  appTitle: APP_TITLE,
  appHeaderColor: APP_HEADER_COLOR,
};
