import { Theme } from "@material-ui/core/es";
import { createMuiTheme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";

export const styles = (muiTheme: Theme) => createStyles({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  main: {
    marginTop: 88,
    width: "100%",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  selectedRowTable: {
    margin: "auto",
    textAlign: "center",
  },
  selectedRowListItem: {
    justifyContent: "center",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  introLogo: {
    position: "absolute",
    bottom: 10,
    width: 200,
    display: "inlineBlock",
  },

  bigButtonWrapper: {
    display: "block",
  },
  panelBody: {
    margin: 10,
  },
  paperPanel: {
    margin: 10,
  },
  linkErrors: {
    flexDirection: "column",
  },
  listItemLinkErrors: {
    justifyContent: "flex-start",
  },
  preWrap: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  href: {
    "color": theme.palette.primary.main,
    "textDecoration": "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  bigColumn: {
    flexBasis: "66.62%",
  },
  column: {
    flexBasis: "33.33%",
  },
  columnHelper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  app: {
    textAlign: "center",
    paddingRight: 30,
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  toolbar: theme.mixins.toolbar,
  placeholderSelector: {
    cursor: "pointer",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
    },
  },
  formSectionWrapper: {

  },
  appBar: {
  },

  topLevelItem: {
    paddingLeft: theme.spacing.unit,
  },

  listItem: {
    "&:hover": {
      backgroundColor: "lightblue",
      borderRadius: 3,
    },
  },

  listItemSection: {
    marginTop: 10,
    fontWeight: 700,
    borderColor: "lightgray",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 3,
    marginBottom: 2,
    "&:hover": {
      backgroundColor: "lightblue",
      borderColor: "black",
    },
    "&$selected, &$selected:hover, &$selected:focus": {
      backgroundColor: BLUE,
    },
  },

  childSelection: {
    minWidth: "45%",
    borderRight: "2px solid lightgray",
    paddingRight: "10px",
  },

  rowCreationSummaryDisplay: {
    marginLeft: 10,
  },

  childItem: {
    "&:hover": {
      backgroundColor: "lightblue",
    },
  },

  landingButton: {
    height: 97,
    display: "block",
    margin: 25,
  },

  uPlanLogo: {
    textAlign: "left",
    height: 56,
    width: 160,
    marginFight: 78,
  },
  appHeader: {
  },

  addCreateFranchiseButton: {
    display: "flex",
    justifyContent: "flex-end",
  },

  nestedItem: {
    marginLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    borderLeftColor: "lightgray",
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
    borderRightColor: "lightgray",
    borderRightWidth: 5,
    borderRightStyle: "solid",
    borderBottomColor: "lightgray",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderRadius: 6,
    width: "92%",
  },

  appTitle: {
    fontSize: "1.5em",
  },

  appIntro: {
    fontSize: "large",
  },

  list: {
    width: "100%",
  },
  successPanel: {
    backgroundColor: "#c2f1bf",
  },
  errorPanel: {
    backgroundColor: "#ffc6c6",
  },
  warningPanel: {
    backgroundColor: "#ffeead",
  },
  requiredField: {
    color: "red",
    flex: "100 1 auto",
  },
  cancelButton: {
    position: "absolute",
    top: 0,
    right: -15,
    color: "black",
  },
  datePicker: {
    borderBottom: "1px solid black",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    font: "inherit",
  },
  uplanContent: {
    display: "flex",
    justifyContent: "flex-start",
  },
  inputSelectionText: {
    padding: "0 1px",
  },
  closeSync: {
    display: "inline-block",
    paddingLeft: 10,
  },
  franchiseItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  },
  removeFranchiseItem: {
    "&:hover": {
      background: "lightblue",
      borderRadius: "3px",
    },
  },
});

const BLUE = "#679dd7";
const GRAY = "#58595B";
const CONTRAST_TEXT = "#000000";

export const theme = createMuiTheme({
  // Overrides the default theme values which can be found here https://material-ui.com/customization/default-theme/
  overrides: {
    MuiButton: {
      root: {
      },
    },
    MuiAppBar: {
      colorPrimary: {
      },
      root: {
        borderTop: 0,
        borderBottom: 1,
        borderLeft: 0,
        borderRight: 0,
      },
    },
    MuiCard: {
      root: {
      },
    },
    MuiPaper: {
      root: {
        padding: 12,
      },
    },
    MuiFormControl: {
      root: {
        minWidth: 100,
        paddingLeft: 20,
      },
    },
    MuiListItem: {
      root: {
        justifyContent: "flex-end",
        "&$selected, &$selected:hover, &$selected:focus": {
          backgroundColor: BLUE,
          borderRadius: 3,
        },
      },
      gutters: {
        paddingRight: 0,
      }
    },
    MuiMenuItem: {
      root: {
        justifyContent: "flex-start",
        "&:hover": {
          backgroundColor: "lightblue",
        },
      },
    },
  },
  palette: {
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    error: {
      main: BLUE,
    },
    grey: {
      A200: GRAY,
    },
    primary: {
      contrastText: CONTRAST_TEXT,
      main: BLUE,
    },
    secondary: {
      contrastText: BLUE,
      main: CONTRAST_TEXT,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from BLUE 500 to BLUE 300 or BLUE 700.
    tonalOffset: 0.2,
  },
});
