/* eslint-disable import/first */
import * as React from "react";
import {connect} from "react-redux";
import {ReduxState} from "../../store/ReduxState";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {InjectedIntlProps, injectIntl} from "react-intl";

import {
  EntitiesResponse,
  EntitySelector,
  EntityType,
  EntityValueSelection,
  FormField,
  NO_ENTITY_TYPE,
  ProjectEntity,
  ProjectEntityValue,
  typeIsSelectorInput,
  typeIsFranchiseMenu,
  typeIsVariableInput,
  VariableSubstitutionField,
} from "@uplan/common";

import {TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { closeInputSelection, openInputFranchise } from "../../store/ui/actions";
import { createProjectRecord, setEntityValueSelection, retireNewestFranchise } from "../../store/builder/actions";
import FranchiseInputDialog from "./FranchiseInputDialog";
import {ErrorDialog} from "../ErrorDialog";

import {withStyles, WithStyles} from "@material-ui/core/styles";

import {styles} from "../theme";
import {entityDisplayOptions, FieldDisplayOverride} from "@uplan/common";

interface OwnProps {
  isSelectionInputOpen: boolean;
  message: string;
  formFieldConfigs: FormField[];
  entities?: EntitiesResponse;
}

type Props = StateProps & DispatchProps & OwnProps & InjectedIntlProps;
const FieldTypeEnum = VariableSubstitutionField.FieldTypeEnum;
class InputDialog extends React.Component<WithStyles<typeof styles> & Props> {
  public state = {
    checkedBoxes: [{}],
    checked: false,
    selectedIndexesByType: {},
    currentFranchiseCombo: "",
    franchiseComboValidated: true,
    inputValuesByType: [],
    requiredFields: [{
      fieldName: "",
      typeId: "",
      required: false,
    }],
    unvalidatedFields: [],
    openErrorDialog: false,
  };

  public render() {
    const { isSelectionInputOpen, isFranchiseInputOpen,
      message, formFieldConfigs, classes, intl } = this.props;
    
    return (
    <div>
      <Dialog
          open={isSelectionInputOpen}
          onClose={this.closeDialog}
          onRendered={this.getInitialState}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">{ message }</DialogTitle>
        <Button className={classes.cancelButton} onClick={this.closeDialog}>X</Button>
        <div>{
          this.state.unvalidatedFields ? this.displayUnvalidatedFields() : null
        }</div>
        <div id="error-dialog-description">
          { formFieldConfigs ? this.getInputList(formFieldConfigs) : null }
        </div>
        <DialogActions>
          <Button onClick={this.closeDialog} variant="contained">
            Cancel
          </Button>
          <Button onClick={this.createRecord(true)} variant="contained" color="primary">
            Test
          </Button>
          <Button onClick={this.createRecord(false)} variant="contained" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <FranchiseInputDialog
        isFranchiseInputOpen={isFranchiseInputOpen}
      />
      <ErrorDialog isOpen={this.state.openErrorDialog}
          intl={intl}
          message={"Error getting entities for the Input Dialog"}
          handleClose={this.closeDialog}
          details={"Please contact an administrator or try your action again."}/>
    </div>
    );
  }

  public displayUnvalidatedFields = () => {
    const { classes } = this.props;
    const unvalidatedFields = this.state.unvalidatedFields;
    const fieldsToDisplay = [];
    if (unvalidatedFields.length >= 1) {
      for (const field of unvalidatedFields) {
        fieldsToDisplay.push(<React.Fragment key={field}>
          <ListItem key={field + "item"} button={false}>
            <ListItemText className={classes.requiredField} disableTypography={true} primary={`${field} is a required field.`}/>
          </ListItem>
        </React.Fragment>);
      }
    }
    return fieldsToDisplay;
  }

  public createNewFranchise = () => {
    const { openInputFranchise: openInputFranchiseThunk } = this.props;
    openInputFranchiseThunk();
  }

  public handleNewFranchiseCreation = (typeId: string, selector: EntitySelector, selectedIndex: number) => {
    const { entities, entitiesById, setEntityValueSelection: setEntityValueThunk } = this.props;
    
    const selection: EntityValueSelection = this.props.selection as EntityValueSelection;
    if (entities) {
      const filtered: FieldDisplayOverride[] = entityDisplayOptions(selector, entities[typeId].entities);
      const cleanFiltered: FieldDisplayOverride[] = filtered.filter((item: FieldDisplayOverride) => item.label !== "*");
      const sortedCleanFiltered: FieldDisplayOverride[] = this.sortedFieldDisplayResults(cleanFiltered);
      const displayOverride: FieldDisplayOverride = sortedCleanFiltered[selectedIndex];
      const entity: ProjectEntity = entitiesById[displayOverride.entityId];
      if (entity) {
        selection[typeId] = [{entity, value: displayOverride.label, field: selector}];
      }
    }
    setEntityValueThunk(selection);
  }

  public addFranchiseAssets = (typeId: string, selector: EntitySelector) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { entities, entitiesById, setEntityValueSelection: setEntityValueThunk } = this.props;
    const selection: EntityValueSelection = this.props.selection as EntityValueSelection;

    this.handleFranchiseComboChange(typeId, e);

    if (entities) {
      const typeEntities = entities[typeId];
      const filtered: FieldDisplayOverride[] = entityDisplayOptions(selector, typeEntities.entities);
      const cleanFiltered: FieldDisplayOverride[] = filtered.filter((item: FieldDisplayOverride) => item.label !== "*");
      const sortedCleanFiltered: FieldDisplayOverride[] = this.sortedFieldDisplayResults(cleanFiltered);

      const selectedFranchiseIndex = this.state.selectedIndexesByType[typeId];
      const selectedFranchise: FieldDisplayOverride = sortedCleanFiltered[selectedFranchiseIndex];

      let selectedEntities: ProjectEntityValue[] = selection[typeId];
      if (selectedFranchise !== undefined) {
        const franchiseLabel = selectedFranchise.label;
        if (selectedEntities === undefined) {
          selectedEntities = [];
          selection[typeId] = selectedEntities;
          selectedEntities.push({entity: entitiesById[selectedFranchise.entityId], value: franchiseLabel, field: selector});
        } else {
          selectedEntities.push({entity: entitiesById[selectedFranchise.entityId], value: franchiseLabel, field: selector});
        }
        setEntityValueThunk(selection);
        this.setState({franchiseComboValidated: true});
        this.state.selectedIndexesByType[typeId] = undefined;
      } else {
        this.setState({franchiseComboValidated: false});
      }
    }
  }

  public getInputList = (formFieldConfigs: FormField[]) => {
    const inputList = [];
    if (formFieldConfigs) {
      const inputConfigs = formFieldConfigs;
      for (const inputItemConfig of inputConfigs) {
        if (inputItemConfig) {
          inputList.push(this.createItems(inputItemConfig));
        }
      }
    }
    return inputList;
  }

  public handleFranchiseComboChange = (typeId: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedIndex = e.target.value;
    this.state.selectedIndexesByType[typeId] = selectedIndex;
    this.setState(this.state);
  }

  public removeFranchiseComboItem = (typeId: string, index: number, field: FormField) => (e: React.MouseEvent) => {
    const { setEntityValueSelection: setEntityValueThunk } = this.props;
    const selection: EntityValueSelection = this.props.selection as EntityValueSelection;

    let franchiseEntities = this.sortedProjectEntityValueResults(selection[typeId]);
    if (!franchiseEntities) {
      franchiseEntities = [];
      selection[typeId] = franchiseEntities;
    }
    franchiseEntities.splice(index,1);

    this.setState(this.state)
    setEntityValueThunk(selection);
  }

  public isValidDate = (dateString: string) => {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if(!dateString.match(regEx)) {
      return false;  // Invalid format
    }
    const d = new Date(dateString);
    if(Number.isNaN(d.getTime())) {
      return false; // Invalid date
    }
    return d.toISOString().slice(0,10) === dateString;
  }

  public handleVariableInputChange = (typeId: string, field: FormField) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { setEntityValueSelection: setEntityValueThunk } = this.props;
    const inputValue = e.target.value;

    const selection: EntityValueSelection = this.props.selection as EntityValueSelection;
    let entityTypeId = typeId === undefined ? NO_ENTITY_TYPE : typeId;
    const variableInput = field as VariableSubstitutionField;
    if (variableInput.entity) {
      entityTypeId = ((variableInput.entity as ProjectEntity).entityType as EntityType).id;
    }
    let variableEntities = selection[entityTypeId];
    if (!variableEntities) {
      variableEntities = [];
      selection[entityTypeId] = variableEntities;
    }
    if (field.fieldType === FieldTypeEnum.DATE) {
      if (!this.isValidDate(inputValue)) {
        return;
      }
    }
    if (variableInput.fieldType === FieldTypeEnum.TEXT || variableInput.fieldType === FieldTypeEnum.DATE) {
      const value = inputValue;
      if (variableEntities.some((item) => item.field.id === field.id)) {
        variableEntities.splice(
          variableEntities.findIndex((item) => item.field.id === field.id),
            1,
        );
        variableEntities.push({
          entity: variableInput.entity as ProjectEntity,
          field: variableInput,
          value,
        });
      } else {
        variableEntities.push({
          entity: variableInput.entity as ProjectEntity,
          field: variableInput,
          value,
        });
      }
    }
    setEntityValueThunk(selection);
  }

  public handleSelectorChange = (typeId: string, selector: EntitySelector) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { entities, entitiesById, setEntityValueSelection: setEntityValueThunk, retireNewestFranchise: retireNewestFranchiseThunk  } = this.props;
    retireNewestFranchiseThunk();
    const selectedIndex = e.target.value;
    this.state.selectedIndexesByType[typeId] = selectedIndex;
    this.setState(this.state);
    const selection: EntityValueSelection = this.props.selection as EntityValueSelection;

    let multiselect: boolean = false;
    if (selector.fieldType === EntitySelector.InputEnum.CHECKBOXGROUP) {
      multiselect = true;
    }
    if (entities) {
      const filtered: FieldDisplayOverride[] = entityDisplayOptions(selector, entities[typeId].entities);
      const cleanFiltered: FieldDisplayOverride[] = filtered.filter((item: FieldDisplayOverride) => item.label !== "*");
      let sortedCleanFiltered: FieldDisplayOverride[] = this.sortedFieldDisplayResults(cleanFiltered);
      let displayOverride: FieldDisplayOverride = sortedCleanFiltered[selectedIndex];
      let entity: ProjectEntity = entitiesById[displayOverride.entityId];
      if (selector.fieldType === EntitySelector.InputEnum.RADIOGROUP ||
        selector.fieldType === EntitySelector.InputEnum.CHECKBOXGROUP) {
          /* Special sorting for radio and checkbox group case */
          sortedCleanFiltered = cleanFiltered.sort(
            (a: FieldDisplayOverride, b: FieldDisplayOverride) => (a.displayOrder > b.displayOrder) ? 1 : -1);
          displayOverride = sortedCleanFiltered[selectedIndex];
          entity = entitiesById[displayOverride.entityId];
      }
      if (entity && multiselect) {
        /* multi selection case */
        let selectedEntities: ProjectEntityValue[] = selection[typeId];
        if (!selectedEntities) {
          selectedEntities = [];
          selection[typeId] = selectedEntities;
          selectedEntities.push({entity, value: displayOverride.label, field: selector});
        } else {
          if (selectedEntities.some((item) => item.entity === entity)) {
            selectedEntities.splice(
              selectedEntities.findIndex((item) => item.entity === entity),
               1,
             );
          } else {
            selectedEntities.push({entity, value: displayOverride.label, field: selector});
          }
        }
      } else {
        /* Single selection case */
        selection[typeId] = [{entity, value: displayOverride.label, field: selector}];
      }
    } else {
      this.setState({openErrorDialog: true})
    }
    setEntityValueThunk(selection);
  }

  public sortedFieldDisplayResults = (cleanFiltered: FieldDisplayOverride[]) => {
    cleanFiltered.sort((a: any, b: any) => {
      const labelA = a.label.toUpperCase(); // ignore upper and lowercase
      const labelB = b.label.toUpperCase(); // ignore upper and lowercase
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return cleanFiltered;
  }

  public sortedProjectEntityValueResults = (items: ProjectEntityValue[]) => {
    items.sort((a: any, b: any) => {
      const valueA = a.value.toUpperCase(); // ignore upper and lowercase
      const valueB = b.value.toUpperCase(); // ignore upper and lowercase
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return items;
  }

  public buildOptions = (selector: EntitySelector, field: FormField) => {
    const { entities, entitiesById, classes, selection } = this.props;
    if (entities) {
      const variableInput = field as VariableSubstitutionField;
      const typeId: string = selector.entityType as string;
      const typeEntities = entities[typeId];
      const required = selector.required;
      const requiredFields = this.state.requiredFields;
      if (required) {
        if (!requiredFields.some((item) => item.fieldName === selector.fieldName)) {
          requiredFields.push({fieldName: selector.fieldName, typeId, required});
        }
      }
      if (typeEntities) {
        const filtered: FieldDisplayOverride[] = entityDisplayOptions(selector, typeEntities.entities);
        const cleanFiltered: FieldDisplayOverride[] = filtered.filter((item: FieldDisplayOverride) => item.label !== "*");
        if (cleanFiltered) {
          if (selector.fieldType === EntitySelector.InputEnum.CHECKBOXGROUP ||
            selector.fieldType === EntitySelector.InputEnum.RADIOGROUP) {
            const sortedFilteredSIC: FieldDisplayOverride[] = cleanFiltered.sort(
              (a: FieldDisplayOverride, b: FieldDisplayOverride) => (a.displayOrder > b.displayOrder) ? 1 : -1);
            // Checkbox Group
            if (selector.fieldType === EntitySelector.InputEnum.CHECKBOXGROUP) {
              this.state.checkedBoxes = [{}]
              const checkboxList = sortedFilteredSIC.map((displayOverride: FieldDisplayOverride, index: number) => {
                const entity = entitiesById[displayOverride.entityId];
                if (entity) {
                  this.state.checkedBoxes.push({entity, value: false});
                  return <FormControlLabel
                      key={entity.id}
                      control={<Checkbox key={entity.id}
                      onChange={this.handleSelectorChange(typeId, selector)}
                      checked={this.state.checkedBoxes[entity.id]}
                      value={index}/>}
                      label={displayOverride.label}/>;
                } else {
                  return null;
                }
              }).filter((exists) => !!exists);
              return <FormControl>
                  <FormGroup>
                    {checkboxList}
                  </FormGroup>
                </FormControl>;
            // Radio Group
            } else if (selector.fieldType === EntitySelector.InputEnum.RADIOGROUP) {
                const radioList = sortedFilteredSIC.map((displayOverride: FieldDisplayOverride, index: number) => {
                  const entity = entitiesById[displayOverride.entityId];
                  if (entity) {
                    return <FormControlLabel
                        key={entity.id}
                        value={index.toString()}
                        control={<Radio/>}
                        label={displayOverride.label}
                    />;
                  } else {
                    return null;
                  }
                }).filter((exists) => !!exists);
                return <FormControl>
                  <RadioGroup
                    value={this.state.selectedIndexesByType[typeId]}
                    //@ts-ignore
                    onChange={this.handleSelectorChange(typeId, selector)}
                  >
                    {radioList}
                  </RadioGroup>
                </FormControl>
            }
          } else {
            const sortedCleanFiltered: FieldDisplayOverride[] = this.sortedFieldDisplayResults(cleanFiltered);
            const newestFranchise = this.props.newestFranchise;
            if (newestFranchise)  {
              for (let i = 0; i < sortedCleanFiltered.length; i++) {
                const currentFranchise = sortedCleanFiltered[i];
                if (currentFranchise.entityId === newestFranchise.id) {
                  this.state.selectedIndexesByType[typeId] = i;
                  this.handleNewFranchiseCreation(typeId, selector, i);
                  break;
                }
              }
            }
            // Franchise Selector
            if (selector.fieldType === EntitySelector.InputEnum.FRANCHISE) {
              return <React.Fragment>
                <List dense={true} disablePadding={true}>
                  <ListItem>
                    <TextField
                      className={"inputTextField"}
                      id={typeId}
                      select={true}
                      label={"Select"}
                      value={this.state.selectedIndexesByType[typeId] >= 0 ? this.state.selectedIndexesByType[typeId] : ""}
                      onChange={this.handleSelectorChange(typeId, selector)}
                      margin="normal"
                    >
                      {sortedCleanFiltered.map((displayOverride: FieldDisplayOverride, index: number) => {
                          const entity = entitiesById[displayOverride.entityId];
                          if (entity) {
                            return <MenuItem disableGutters={true} key={entity.id} value={index}>
                              {displayOverride.label}
                            </MenuItem>;
                          } else {
                            return null;
                          }
                      }).filter((exists) => !!exists)}
                    </TextField>
                  </ListItem>
                  <ListItem className={classes.addCreateFranchiseButton}>
                    <Button onClick={this.createNewFranchise} size="small" variant="contained" color="primary">
                      Create New Franchise
                    </Button>
                  </ListItem>
                </List>
              </React.Fragment>;
            // Franchise Menu
            } else if (selector.fieldType === EntitySelector.InputEnum.FRANCHISEMENU) {
              const franchiseListItemArray = [];
              if (selection[typeId] !== undefined) {
                const sortedFranchiseComboList: ProjectEntityValue[] = this.sortedProjectEntityValueResults(selection[typeId]);
                for (const [index, selectedItem] of sortedFranchiseComboList.entries()) {
                  franchiseListItemArray.push(<React.Fragment key={selectedItem.value}>
                                                <ListItem dense={true} key={selectedItem.value + "item"} className={classes.franchiseItem}>
                                                  <ListItemText secondary={selectedItem.value}/>
                                                  <button onClick={this.removeFranchiseComboItem(typeId, index, field)}
                                                    className={classes.removeFranchiseItem}>
                                                    X
                                                  </button>
                                                </ListItem>
                                              </React.Fragment>);
                }
              }

              return <React.Fragment>
                <List dense={true} disablePadding={true}>
                  <ListItem>
                    {this.state.franchiseComboValidated ? null :
                      <ListItem button={false}>
                        <ListItemText className={classes.requiredField} disableTypography={true}
                          primary={"Franchise Combination is a required field."}/>
                      </ListItem>
                    }
                    <TextField
                      className={"inputTextField"}
                      id={"franchise-combo-selector"}
                      select={true}
                      label={"Select"}
                      value={this.state.selectedIndexesByType[typeId] >= 0 ? this.state.selectedIndexesByType[typeId] : ""}
                      onChange={this.addFranchiseAssets(typeId, selector)}
                      margin="normal"
                    >
                      {sortedCleanFiltered.map((displayOverride: FieldDisplayOverride, index: number) => {
                            const entity = entitiesById[displayOverride.entityId];
                            if (entity) {
                              return <MenuItem disableGutters={true} key={entity.id} value={index}>
                                {displayOverride.label}
                              </MenuItem>;
                            } else {
                              return null;
                            }
                        }).filter((exists) => !!exists)}
                    </TextField>
                  </ListItem>
                  {franchiseListItemArray}
                </List>
              </React.Fragment>;
            // Anything else that has a typeId but isn't one of the above cases.
            } else {
              return <TextField
                className={"inputTextField"}
                id={typeId}
                select={true}
                label={"Select"}
                value={this.state.selectedIndexesByType[typeId] >= 0 ? this.state.selectedIndexesByType[typeId] : ""}
                onChange={this.handleSelectorChange(typeId, selector)}
                margin="normal"
              >
                {sortedCleanFiltered.map((displayOverride: FieldDisplayOverride, index: number) => {
                    const entity = entitiesById[displayOverride.entityId];
                    if (entity) {
                      return <MenuItem disableGutters={true} key={entity.id} value={index}>
                        {displayOverride.label}
                      </MenuItem>;
                    } else {
                      return null;
                    }
                }).filter((exists) => !!exists)}
              </TextField>;
            }
          }
          return;
        } else {
          return <div>Unable to build options, no filtered results found.</div>;
        }
      // Variable inputs that may or may not have a typeId.
      } else if (variableInput.fieldType === FieldTypeEnum.TEXT) {
        const dynamicTypeId = selector.entityType === null ? NO_ENTITY_TYPE : selector.id;
        return <TextField
              className={"inputTextField"}
              id={dynamicTypeId}
              label={""}
              type={"text"}
              value={this.state.inputValuesByType[dynamicTypeId]}
              onChange={this.handleVariableInputChange(dynamicTypeId, field)}
              margin="normal"
            />;
      } else if (variableInput.fieldType === FieldTypeEnum.DATE) {
        const dynamicTypeId = selector.entityType === null ? NO_ENTITY_TYPE : selector.id;
        return <input
              id={dynamicTypeId}
              type="date"
              className={classes.datePicker}
              placeholder={""}
              value={this.state.inputValuesByType[dynamicTypeId]}
              onChange={this.handleVariableInputChange(dynamicTypeId, field)}
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              min="2000-01-01"
              max="2099-01-01"
            />;
      } else {
        console.error(`There were no entities for entity type id: ${typeId}`);
        return <div>No entity id: {typeId} </div>;
      }
    } else {
      return <div>Unable to build options</div>;
    }
  }

  public getInitialState = () => {
    return this.setState({
      checkedBoxes: [{}],
      checked: false,
      selectedIndexesByType: {},
      currentFranchiseCombo: "",
      franchiseComboValidated: true,
      inputValuesByType: [],
      requiredFields: [{
        fieldName: "",
        typeId: "",
        required: false,
      }],
      unvalidatedFields: [],
      openErrorDialog: false,
    });
  }

  /*
   */

  private createItems = (formFieldConfig: FormField) => {
    const { classes } = this.props;
    const input = typeIsSelectorInput(formFieldConfig.fieldType)
      || typeIsFranchiseMenu(formFieldConfig.fieldType)
      || typeIsVariableInput(formFieldConfig.fieldType)
                  ? this.buildOptions(formFieldConfig as EntitySelector, formFieldConfig as FormField)
                  : <Input key={formFieldConfig.id + "input"} type={formFieldConfig.fieldType}/>;
    const required = formFieldConfig.required;
    return <React.Fragment key={formFieldConfig.id}>
             <ListItem divider={true} key={formFieldConfig.id + "item"} button={false}>
              <ListItemText primary={formFieldConfig.fieldName} className={classes.inputSelectionText}/>
              {required ? <div className={classes.requiredField}>*</div> : null}
               { input }
             </ListItem>
            </React.Fragment>;
  }

  private createRecord = (dryRun: boolean) => () => {
    const { createProjectRecord: createProjectRecordThunk } = this.props;
    const selection: EntityValueSelection = this.props.selection as EntityValueSelection;
    let validated = false;
    const requiredFields = this.state.requiredFields.filter((item) => item.required !== false);
    const totalRequiredFields = requiredFields.length;
    let validatedFields = 0;
    const unvalidatedFields: string[] = [];
    for (const field of requiredFields) {
      const dynamicTypeId = field.typeId === null ? NO_ENTITY_TYPE : field.typeId;
      const indexSelection = this.state.selectedIndexesByType[dynamicTypeId];
      const selectionCheck = indexSelection !== undefined ? indexSelection : selection[dynamicTypeId];
      if (selectionCheck !== undefined) {
        if (selection[dynamicTypeId].some((item) => item.value !== "")) {
          validatedFields = validatedFields + 1;
        } else {
          unvalidatedFields.push(field.fieldName);
        }
      } else {
        unvalidatedFields.push(field.fieldName);
      }
    }
    this.setState({unvalidatedFields});
    if (validatedFields === totalRequiredFields) {
      validated = true;
    }

    if (validated) {
      createProjectRecordThunk(dryRun);
      this.closeDialog();
    }
  }

  private closeDialog = () => {
    const { closeInputSelection: closeInputSelectionThunk, setEntityValueSelection: setEntityValueThunk} = this.props;
    this.getInitialState();
    setEntityValueThunk({});
    closeInputSelectionThunk();
  }

}

interface DispatchProps {
  setEntityValueSelection: typeof setEntityValueSelection;
  createProjectRecord: typeof createProjectRecord;
  closeInputSelection: typeof closeInputSelection;
  openInputFranchise: typeof openInputFranchise;
  retireNewestFranchise: typeof retireNewestFranchise;
}

const mapDispatchToProps: DispatchProps = {
  setEntityValueSelection,
  createProjectRecord,
  closeInputSelection,
  openInputFranchise,
  retireNewestFranchise,
};

interface StateProps {
  selection: EntityValueSelection;
  isFranchiseInputOpen: boolean;
  newestFranchise?: ProjectEntity;
  entitiesByName: {[name: string]: ProjectEntity[]};
  entitiesById: {[id: string]: ProjectEntity};
}

function mapStateToProps(state: ReduxState, props: OwnProps): StateProps {
  const { builderStore, uiStore } = state;

  return {
    newestFranchise: builderStore.newestFranchise,
    isFranchiseInputOpen: uiStore.isFranchiseInputOpen,
    selection: builderStore.selection,
    entitiesByName: builderStore.entitiesByName,
    entitiesById: builderStore.entitiesById,
  };
}

const intlInputDialogHOC = injectIntl(InputDialog);
const styledInputDialogThunk = withStyles(styles, { withTheme: true });
const styledInputDialogHOC = styledInputDialogThunk(intlInputDialogHOC);

// Nifty three parameter generic connect that indicates where all this components props come from
// The first one describes the props that mapStateToProps grabs from redux state
// The second one describes the props that magical mapDispatchToProps grabs from the action creators
// The third one is the public interface that describes props expected from a parent component
export default connect<StateProps, DispatchProps, OwnProps, ReduxState>(mapStateToProps, mapDispatchToProps)<any>
                        (styledInputDialogThunk(styledInputDialogHOC));
