import {wrappedFetch} from "../../util/wrappedFetch";
import {Dispatch} from "redux";
import {errorAction} from "../ui/actions";
import {
  LAUNCH_CELL_LINKER_REQUEST,
  LAUNCH_CELL_LINKER_RESPONSE,
  RULE_SET_MAP_REQUEST,
  RULE_SET_MAP_RESPONSE, SYNC_JOB_REQUEST, SYNC_JOB_RESPONSE,
} from "./types";
import {
  JobStatusResponse,
  LinkerPostRequest,
  LinkerPostResponse,
  RequestError,
  RulesetRowsResponse,
  TriggerJobRequest,
  TriggerJobResponse,
} from "@uplan/common";
import {config} from "../../config";
import {ReduxState} from "../ReduxState";

// Seems like having endpoint constants defined in a common constant file
// would be a good idea
const RULE_SET_MAP_URL = `${config.apiBase}cellLinker`;
export const JOB_URL = `${config.apiBase}job`;
export const JOB_STATUS_URL = `${config.apiBase}jobStatus`;

export const loadCellLinkingRules = () => async (dispatch: Dispatch, getState: () => ReduxState) => {
  try {
    // Seems like typesafe actions would be a good idea.
    dispatch({type: RULE_SET_MAP_REQUEST});

    const rulesetFetchResponse = await wrappedFetch(`${RULE_SET_MAP_URL}`);
    if (rulesetFetchResponse.status > 299) {
      const linkerError: RequestError = await rulesetFetchResponse.json();
      errorAction("error_launching_cell_linker", linkerError.message)(dispatch);
    } else {
      const ruleset: RulesetRowsResponse = await rulesetFetchResponse.json();

      dispatch({
        type: RULE_SET_MAP_RESPONSE,
        ruleset,
      });
    }
  } catch (e) {
    errorAction("error_loading_cell_linking_rules", e.message)(dispatch);
  }
};

export const runSyncJob = (jobType: "FORMULA_SYNC" | "TITLE_PLAN_SYNC" | "TITLE_SUMMARY_SYNC" | "MULTI-TITLE_MULTI-TITLE_CELL_LINKING") => async (dispatch: Dispatch, getState: () => ReduxState) => {
  dispatch({type: SYNC_JOB_RESPONSE});
  try {
    // Seems like typesafe actions would be a good idea.
    dispatch({type: SYNC_JOB_REQUEST});

    const triggerJobRequest: TriggerJobRequest = {
      appName: getState().uiStore.appName as string,
      jobType,
    };

    const triggerJobResponse = await wrappedFetch(`${JOB_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(triggerJobRequest),
    });

    const response: Response = await triggerJobResponse;
    if (response.status > 299) {
      const linkerError: RequestError = await response.json();
      errorAction("error_syncing", linkerError.message)(dispatch);
    } else {
      const jobResponse: TriggerJobResponse = await triggerJobResponse.json();
      const jobId = jobResponse.jobId;
      const intervalId = setInterval(async () => {
        const jobStatusResponse = await wrappedFetch(`${JOB_STATUS_URL}/${jobId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (jobStatusResponse.status > 299) {
          const statusError: RequestError = await jobStatusResponse.json();
          errorAction("error_syncing", statusError.message)(dispatch);
          clearInterval(intervalId);
        } else {
          const statusResponse: JobStatusResponse = await jobStatusResponse.json();
          if (statusResponse.isComplete) {
            clearInterval(intervalId);
            dispatch({
                       type: SYNC_JOB_RESPONSE,
                     });
          } else {
            // Keep interval'ing
          }
        }

      }, 5000);
    }
  } catch (e) {
    errorAction("error_syncing", e.message)(dispatch);
  }
};

export const launchCellLinker = (request: LinkerPostRequest) => async (dispatch: Dispatch, getState: () => ReduxState) => {
  try {
    // Seems like typesafe actions would be a good idea.
    dispatch({type: LAUNCH_CELL_LINKER_REQUEST});

    const triggerJobRequest: TriggerJobRequest = {
      appName: getState().uiStore.appName as string,
      jobType: "CELL_LINKING",
      payload: request,
    };

    const triggerJobResponse = await wrappedFetch(`${JOB_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(triggerJobRequest),
    });

    const response: Response = await triggerJobResponse;
    if (response.status > 299) {
      const linkerError: RequestError = await response.json();
      errorAction("error_launching_cell_linker", linkerError.message)(dispatch);
    } else {
      const jobResponse: TriggerJobResponse = await triggerJobResponse.json();
      const jobId = jobResponse.jobId;
      const intervalId = setInterval(async () => {
        const jobStatusResponse = await wrappedFetch(`${JOB_STATUS_URL}/${jobId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (jobStatusResponse.status > 299) {
          const statusError: RequestError = await jobStatusResponse.json();
          errorAction("error_launching_cell_linker", statusError.message)(dispatch);
          clearInterval(intervalId);
        } else {
          const statusResponse: JobStatusResponse = await jobStatusResponse.json();
          if (statusResponse.isComplete && statusResponse.payload) {
            clearInterval(intervalId);
            dispatch({
                       type: LAUNCH_CELL_LINKER_RESPONSE,
                       linkerResponse: statusResponse.payload as LinkerPostResponse,
                     });
          } else {
            // Keep interval'ing
          }
        }

      }, 5000);
    }
  } catch (e) {
    errorAction("error_launching_cell_linker", e.message)(dispatch);
  }
};
