import * as React from "react";
import {connect} from "react-redux";
import {ReduxState} from "../../store/ReduxState";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {TextField} from "@material-ui/core";

import { closeInputFranchise } from "../../store/ui/actions";
import { createFranchise } from "../../store/builder/actions";

import { CreateFranchiseRequest } from "@uplan/common";

interface OwnProps {
  isFranchiseInputOpen: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;
class FranchiseInputDialog extends React.Component<Props> {
  public initialState = {
    name: "",
    shortName: "",
  };

  public state = {
    name: "",
    shortName: "",
  };

  public handleClose = () => {
    const { closeInputFranchise: closeInputFranchiseThunk } = this.props;
    this.setState(this.initialState);
    closeInputFranchiseThunk();
  }

  public handleCreate = () => {
    const { createFranchise: createFranchiseThunk } = this.props;
    if (this.state.name !== ""){
      if (this.state.shortName === "") {
        this.state.shortName = this.state.name;
      }
      const franchisePayload: CreateFranchiseRequest = {name: this.state.name, shortName: this.state.shortName};
      if (franchisePayload) {
        createFranchiseThunk(franchisePayload);
      }
    }
  }

  public handleChange = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputId = e.target.id;
    const inputValue = e.target.value;
    this.setState({[inputId]: inputValue});
  }

  public render() {
    const { isFranchiseInputOpen, builderIsFetching } = this.props;
    return (
    <div>
      <Dialog
          open={isFranchiseInputOpen}
          onClose={this.handleClose}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">Enter a New Franchise</DialogTitle>
        <div id="error-dialog-description">
          <ListItem divider={true} button={false}>
            <ListItemText primary={"Name"}/>
            <TextField
                required={true}
                className={"inputTextField"}
                id={"name"}
                label={"Name"}
                value={this.state.name}
                onChange={this.handleChange()}
                margin="normal"
              />
          </ListItem>
          <ListItem divider={true} button={false}>
            <ListItemText primary={"Short Name"}/>
            <TextField
                className={"inputTextField"}
                id={"shortName"}
                label={"Short Name"}
                value={this.state.shortName}
                onChange={this.handleChange()}
                margin="normal"
              />
          </ListItem>
        </div>
        <DialogActions>
          <Button onClick={this.handleClose} variant="contained">
            Close
          </Button>
          <Button onClick={this.handleCreate} variant="contained" color="primary" disabled={builderIsFetching}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
}

interface DispatchProps {
  closeInputFranchise: typeof closeInputFranchise;
  createFranchise: typeof createFranchise;
}

const mapDispatchToProps: DispatchProps = {
  closeInputFranchise,
  createFranchise,
}

interface StateProps {
  isFranchiseInputOpen: boolean;
  builderIsFetching: boolean;
}

function mapStateToProps(state: ReduxState, props: OwnProps): StateProps {
  const { uiStore, builderStore } = state;

  return {
    isFranchiseInputOpen: uiStore.isFranchiseInputOpen,
    builderIsFetching: builderStore.isFetching
  };
}

export default connect<StateProps, DispatchProps, OwnProps, ReduxState>(mapStateToProps, mapDispatchToProps)<any>(FranchiseInputDialog);
