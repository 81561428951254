import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import List from "@material-ui/core/List/List";
import Collapse from "@material-ui/core/Collapse/Collapse";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {withStyles, WithStyles} from "@material-ui/core/styles";

import {styles} from "../theme";
import InputDialog from "./InputDialog";
import {
  addHiddenFieldValuesToSelection,
  EntitiesResponse,
  EntityValueSelection,
  FormField,
  Menu,
  ProjectEntity,
  UIConfig,
} from "@uplan/common";

import {InjectedIntlProps, injectIntl} from "react-intl";
import * as React from "react";
import {connect} from "react-redux";
import {ReduxState} from "../../store/ReduxState";
import {closeInputSelection, errorAction, openInputSelection} from "../../store/ui/actions";
import {setEntityValueSelection,} from "../../store/builder/actions";

// Props provided by parent components
interface OwnProps {
  loadChildSelection: Menu;
}

interface CategoryOptionsState {
  openMenuItems: {[id: string]: boolean};
  selectedFormFieldConfigs: FormField[];
  selectedMenuItemConfigLabelKey: string;
  selectedItem: string;
}

type Props = StateProps & DispatchProps & OwnProps;

export class CategoryOptions extends React.Component<WithStyles<typeof styles> & OwnProps & InjectedIntlProps & Props, CategoryOptionsState> {

  public state = {
    openMenuItems: {},
    selectedFormFieldConfigs: [],
    selectedMenuItemConfigLabelKey: "",
    selectedItem: "",
  };

  public handleMenuClick = (menuConfig: Menu) => () => {
    if (menuConfig) {
      this.state.openMenuItems[menuConfig.id] = !this.state.openMenuItems[menuConfig.id];
      this.setState({openMenuItems:  this.state.openMenuItems});
    }
  }

  // This will add all the hidden inputs and all the inputs
  // under the ShoppingCart
  public addHiddenInputs(menuConfig: Menu) {
    // This switch will disable the code that adds dummy values for the inputs for testing.
    const { entitiesById, entitiesByType, setEntityValueSelection: setValuesThunk } = this.props;
    const selection: EntityValueSelection = {};
    for (const section of menuConfig.sectionEditConfigs) {
      addHiddenFieldValuesToSelection(section, selection, entitiesById, entitiesByType);
    }

    setValuesThunk(selection);
  }

  public inputDialogMenu = (menuConfig: Menu) => () => {
    const { intl, errorAction: errorActionThunk, openInputSelection: openInputSelectionThunk, closeInputSelection: closeInputSelectionThunk } = this.props;
    if (menuConfig.sectionEditConfigs[0]) {
      this.addHiddenInputs(menuConfig);
      const formFieldConfigsArr = menuConfig.sectionEditConfigs[0].formFieldConfigs;
      openInputSelectionThunk();
      this.setState({
        selectedFormFieldConfigs: formFieldConfigsArr,
        selectedMenuItemConfigLabelKey: menuConfig.labelKey
      });
    } else {
      closeInputSelectionThunk();
      if (errorActionThunk) {
        errorActionThunk("no_form_sections_title", intl.formatMessage({id: "no_form_sections_details"}));
      } else {
        console.log("The error action thunk isn't defined.");
      }
    }
  };

  public render = () => {
    const { loadChildSelection, entitiesByType, classes, isSelectionInputOpen } = this.props;
    const childMenuList = this.getMenuList(loadChildSelection);
    const navBar = document.getElementById('navBar') as HTMLElement;
    if (navBar) {
      const navBarWidth = navBar.offsetWidth as any;
      const childSelectionContent = document.getElementById('childSelectionContent') as HTMLElement;
      childSelectionContent.style.marginLeft = `${navBarWidth + 30}px`;
    }

    if (!loadChildSelection) {
      return <div id="childSelectionContent" className={classes.childSelection}>
        Please select a category from the menu on the left.
      </div>
    } else {
      return <div id="childSelectionContent" className={classes.childSelection}>
        <InputDialog isSelectionInputOpen={isSelectionInputOpen}
          entities={entitiesByType}
          message={`Enter ${this.state.selectedMenuItemConfigLabelKey} Details`}
          formFieldConfigs={this.state.selectedFormFieldConfigs}/>
        {loadChildSelection ? childMenuList : null}
      </div>
    }
  }

  public getMenuList = (parentMenuConfig: Menu | UIConfig, topLevel: boolean = true) => {
    const menuList = [];
    if (parentMenuConfig) {
      const menuConfigs = parentMenuConfig.childrenMenus;
      for (const menuItemConfig of menuConfigs) {
        if (menuItemConfig.childrenMenus) {
          const hasChildren = menuItemConfig.childrenMenus.length > 0;
          hasChildren ? topLevel = true : topLevel = false;
          menuList.push(this.createItems(menuItemConfig, topLevel, hasChildren));
        }
      }
    }
    return menuList;
  }

  private createItems = (menuItemConfig: Menu, topLevel: boolean, hasChildren: boolean) => {
    const { classes } = this.props;
    // hasFormSections probably be used to determine whethe or not to display the new button.
    // But the way it is now is good for MVP.  Post MVP a button will be presented for each
    // sectionEditConfig
    // const hasFormSections = menuItemConfig.sectionEditConfigs.length > 0;
    const activeItem = this.state.selectedItem === menuItemConfig.id;
    return <React.Fragment key={menuItemConfig.id}>
             <ListItem className={hasChildren ? classes.listItemSection :
                classes.listItem + " " + classes.nestedItem + " " + "buttonControl"} selected={activeItem}
                key={menuItemConfig.id + "item"} button={false} onClick={this.handleMenuClick(menuItemConfig)}>
                <ListItemText className={classes.topLevelItem} primary={menuItemConfig.labelKey} disableTypography={true}/>
                {hasChildren ? this.state.openMenuItems[menuItemConfig.id] ?
                  <ExpandLess/> :
                  <ExpandMore/> :
                  <Button className={"buttonHover"} variant="contained" color="primary" onClick={this.inputDialogMenu(menuItemConfig)}>New</Button>}
             </ListItem>
               {hasChildren ?
               <Collapse key={menuItemConfig.id + "collapse"}
                    in={!this.state.openMenuItems[menuItemConfig.id]} timeout="auto"
                    unmountOnExit={true}>
                 <List disablePadding={true}>
                   {hasChildren ? this.getMenuList(menuItemConfig, false) : null}
                 </List>
               </Collapse>
               : null}
            </React.Fragment>
  }
}

interface DispatchProps {
  errorAction: typeof errorAction;
  setEntityValueSelection: typeof setEntityValueSelection;
  openInputSelection: typeof openInputSelection;
  closeInputSelection: typeof closeInputSelection;
}

const mapDispatchToProps: DispatchProps = {
  errorAction,
  setEntityValueSelection,
  openInputSelection,
  closeInputSelection,
};

interface StateProps {
  isSelectionInputOpen: boolean;
  entitiesByType: EntitiesResponse; // It'd be nice if the reducer rehydrated everything
  entitiesById: {[id: string]: ProjectEntity};
}

function mapStateToProps (state: ReduxState, props: OwnProps): StateProps {
  const { builderStore, uiStore } = state;

  return {
    isSelectionInputOpen: uiStore.isSelectionInputOpen,
    entitiesByType: builderStore.entitiesByType,
    entitiesById: builderStore.entitiesById,
  };
}

const intlCategoryOptionsHOC = injectIntl(CategoryOptions);
const styledCategoryOptionsThunk = withStyles(styles, { withTheme: true });
const styledCategoryOptionsHOC = styledCategoryOptionsThunk(intlCategoryOptionsHOC);

// Nifty three parameter generic connect that indicates where all this components props come from
// The first one describes the props that mapStateToProps grabs from redux state
// The second one describes the props that magical mapDispatchToProps grabs from the action creators
// The third one is the public interface that describes props expected from a parent component
export default connect<StateProps, DispatchProps, OwnProps, ReduxState>(mapStateToProps, mapDispatchToProps)<any>
                        (styledCategoryOptionsThunk(styledCategoryOptionsHOC));
