import * as React from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect, InferableComponentEnhancerWithProps} from "react-redux";
import {ReduxState} from "../../store/ReduxState";
import {styles} from "../theme";
import {withStyles, WithStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

interface OwnProps {
  isSyncing: boolean,
  syncComplete: boolean,
}

class SyncJobStatus extends React.Component<WithStyles<typeof styles> & OwnProps & InjectedIntlProps> {

  public render() {
    const { isSyncing, syncComplete, classes } = this.props;
    if (!isSyncing && syncComplete) {
      return (
          <Paper id="syncCompletedPanel" className={classes.paperPanel}>
            <Button variant="outlined" color="secondary" onClick={this.closeSyncPanel}>Close</Button>
            <div className={classes.closeSync}><Typography variant="button">Sync job completed... </Typography></div>
          </Paper>
      );
    } else if (isSyncing){
      return (
          <Paper className={classes.paperPanel}>
            <div><Typography variant="button">Running job... </Typography></div>
          </Paper>
      );
    } else {
      return <div/>
    }
  }

  private closeSyncPanel = () => {
    const syncCompletedPanel = document.getElementById("syncCompletedPanel") as HTMLElement;
    syncCompletedPanel.remove();
  }
}

function mapStateToProps(state: ReduxState) {
  const { cellLinkerStore } = state;

  const isSyncing = cellLinkerStore.isSyncing;
  const syncComplete = cellLinkerStore.syncComplete;
  return { isSyncing, syncComplete };
}

const intlCellLinkerHOC = injectIntl(SyncJobStatus);
const styledCellLinkerThunk = withStyles(styles);
const styledCellLinkerHOC = styledCellLinkerThunk(intlCellLinkerHOC);
const reduxConnectedThunk: InferableComponentEnhancerWithProps<OwnProps, any> = connect(mapStateToProps, { } );
export default reduxConnectedThunk(styledCellLinkerHOC);

