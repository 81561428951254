import { combineReducers } from "redux";
import { builderStore } from "./builder/reducers";
import { authStore } from "./auth/reducers";
import { uiStore } from "./ui/reducers";
import {cellLinkerStore} from "./cellLinker/reducers";

const rootReducer = combineReducers({
  "uiStore": uiStore,
  "builderStore": builderStore,
  //@ts-ignore
  "authStore": authStore,
  "cellLinkerStore": cellLinkerStore
});

export default rootReducer;
