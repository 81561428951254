export const messages = {
  SMART_SHEETS: "Smart Sheets",
  error_title: "Error",
  nomessage: "No message provided",
  ok_label: "OK",
  error_loading_ui_Config: "Error loading UIConfig",
  go_to_admin_console: "Go to Admin console",
  go_home: "Go home",
  input_selection_instructions: "Select the inputs that correlate to the rows you are adding",
  project_wizard: "Project Wizard",
  cell_linker: "Cell Linker",
  admin_console: "Admin Console",
  admin_console_coming_soon: "Admin console coming soon...",
  getting_started_instructions: " ",
  no_config_message: "No UI Configuration elements were found in the database.  You need to run a sheet ingestion.",
  create_label: "Create",
  cancel_label: "Cancel",
  error_loading_ui_config: "Error loading UI configuration elements",
  error_loading_cell_linking_rules: "Error loading cell linking rules",
  error_authenticating: "Error authenticating",
  error_revoking_token: "There was an error while trying to get revoke your authentication token.",
  not_authorized_details: "Redirecting.  You are not authorized to use this app. Are you on the list of authorized users?",
  not_authorized: "Not Authorized",
  error_getting_token: "There was an error while trying to get an authentication token.",
  logoff_user: "Logoff {username}",
  cell_linker_link_text: "Cell Linker",
  wizard_link_text: "Wizard",
  error_launching_cell_linker: "There was a problem launching the cell linker.",
  error_running_job: "There was a problem running the job request.",
  found_links: "{linkCount} links found",
  found_link: "{linkCount} link found",
  number_of_link_errors: "{linkErrorCount} link errors",
  number_of_link_error: "{linkErrorCount} link error",
  launch_linker: "Launch Linker",
  linking: "Please wait for the linker",
  wait: "Please wait",
  building_rows: "Please wait while base rows are materializing",
  retrieving_configuration: "Retrieving Configuration",
  dry_run: "Dry Run",
  link_errors: "Link Errors",
  links_into: "Links Into",
  no_link_errors: "No Link Errors",
  no_parse_errors: "No Parse Errors",
  parse_errors: "Parse Errors",
  description: "Description",
  expectation: "Expectation",
  explanation: "Explanation",
  success: "Success",
  ingest_ui_config: "Ingest UI Config",
  sync_title_plan: "Add Ref Versions to TP",
  sync_title_summaries: "Add New Multi-Title Rows",
  create_multi_title_links: "Create Multi-title Links",
  sync_formulas: "Sync Formulas",
  syncing: "Syncing",
  post_a_record: "Post a record",
  error_submitting_project_record: "Error creating project record",
  no_form_sections_title: "No form sections found",
  no_form_sections_detail: "There were no form sections in the ui configuration for this section.",
  unknown_input_type_details: "The Input type '{type}' has not been implemented.",
  unknown_input_type_title: "Unknown Input Type",
  error_fetching_project_entities: "Error fetching project entities",
  error_fetching_template_rows: "Error fetching template rows",
  error_syncing: "Error running sync job",
  no_entity_types_were_found: "No entity types found",
  no_entity_types_were_found_details: "No entities were found for '{type}'",
  error_creating_franchise: "There was an error during franchise creation",
};
