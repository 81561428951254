import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

import { withStyles, WithStyles} from "@material-ui/core/styles";
import {Menu, UIConfig, FormField} from "@uplan/common";
import * as React from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
//@ts-ignore
import ResizeAware from "react-resize-aware";

import logo from '../../assets/uPlanLogo.png';
import {loadChildSelection, closeInputSelection, openInputSelection} from "../../store/ui/actions";
import InputDialog from "./InputDialog";
import {ErrorDialog} from "../ErrorDialog";
import {styles} from "../theme";
import { connect } from 'react-redux';
import {ReduxState} from "../../store/ReduxState";

interface OwnProps {
  uiConfig: UIConfig;
  navDrawerOpen: boolean;
  onDrawerClose: () => void;
  onResizeDrawer: (bounds: any) => void;
  loadChildSelection: Menu;
}

interface NavMenuState {
  openMenuItems: {[id: string]: boolean};
  openErrorDialog: boolean;
  selectedFormFieldConfigs: FormField[];
  selectedMenuItemConfigLabelKey: string;
  selectedItem: string;
}
type Props = StateProps & DispatchProps & OwnProps;

class NavMenu extends React.Component<WithStyles<typeof styles> & Props & InjectedIntlProps, NavMenuState> {


  public state = {
    openMenuItems: {},
    openErrorDialog: false,
    selectedFormFieldConfigs: [],
    selectedMenuItemConfigLabelKey: "",
    selectedItem: "",
  };

  public handleMenuClick = (menuConfig: Menu) => () => {
    const hasChildren = menuConfig.childrenMenus.length > 0;
    const currentSelection = menuConfig.id;
    const previousSelection = this.state.selectedItem;
    if (hasChildren) {
      if (currentSelection !== previousSelection) {
        this.props.loadChildSelection(menuConfig);
        this.setState({selectedItem: currentSelection});
      }
    }
  }

  public inputDialogMenu = (menuConfig: Menu) => () => {
    const { openInputSelection: openInputSelectionThunk, closeInputSelection: closeInputSelectionThunk } = this.props;
    if (menuConfig.sectionEditConfigs[0]) {
      const formFieldConfigsArr = menuConfig.sectionEditConfigs[0].formFieldConfigs;
      openInputSelectionThunk();
      this.setState({selectedFormFieldConfigs: formFieldConfigsArr});
      this.setState({selectedMenuItemConfigLabelKey: menuConfig.labelKey});
    } else {
      this.setState({openErrorDialog: true});
      closeInputSelectionThunk();
    }
  }


  public render = () => {
    const { uiConfig, onDrawerClose, onResizeDrawer, navDrawerOpen, intl, classes, isSelectionInputOpen } = this.props;

    const drawer = (
        <ResizeAware onResize={onResizeDrawer}>
        <div id="navBar">
          <div className={classes.toolbar} >
            <img src={logo} className={classes.uPlanLogo} alt="logo"/>
          </div>
          <Divider />
            {uiConfig ? this.getMenuList(uiConfig) : <div/>}
        </div>
        </ResizeAware>
    );
    if (!uiConfig) {
      return <div/>
    } else {
      return <nav className={classes.drawer}>
        {/* The implementation can be swap with js to avoid SEO duplication of links. */}
        <InputDialog isSelectionInputOpen={isSelectionInputOpen}
          message={`Enter ${this.state.selectedMenuItemConfigLabelKey} Details`}
          formFieldConfigs={this.state.selectedFormFieldConfigs}/>

        <ErrorDialog isOpen={this.state.openErrorDialog}
          intl={intl}
          message={"Error getting SectionEditConfigs from the selection"}
          handleClose={this.closeDialog.bind(this, "error")}
          details={"Please contact an administrator or try your action again."}/>

        <Hidden smUp={true} implementation="css">
          <Drawer
              variant="temporary"
              anchor={"left"}
              onClose={onDrawerClose}
              open={navDrawerOpen}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown={true} implementation="css">
          <Drawer
              variant="permanent"
              open={true}>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    }
  }

  public getMenuList = (parentMenuConfig: Menu | UIConfig, topLevel: boolean = true) => {
    const menuList = [];
    if (parentMenuConfig) {
      const menuConfigs = parentMenuConfig.childrenMenus;
      if (menuConfigs) {
        for (const menuItemConfig of menuConfigs) {
          const hasFormSections = menuItemConfig.sectionEditConfigs.length > 0;
          menuList.push(this.createItems(menuItemConfig, topLevel, hasFormSections));
        }
      }
    }
    return menuList;
  }

  private createItems = (menuItemConfig: Menu, topLevel: boolean, hasFormSections: boolean) => {
    const { classes } = this.props;
    const activeItem = this.state.selectedItem === menuItemConfig.id;
    return <React.Fragment key={menuItemConfig.id}>
             <ListItem className={classes.listItem + " " + "buttonControl"} key={menuItemConfig.id + "item"} button={false}
                selected={activeItem} onClick={this.handleMenuClick(menuItemConfig)}>
                <ListItemText className={classes.topLevelItem} primary={menuItemConfig.labelKey}/>
                {hasFormSections ? <Button className={"buttonHover"} variant="contained" color="primary"
                  onClick={this.inputDialogMenu(menuItemConfig)}>New</Button> : null}
             </ListItem>
            </React.Fragment>;
  }

  private closeDialog = (dialog: string) => {
    if (dialog === "error") {
      this.setState({openErrorDialog: false})
    }
  }
}

interface DispatchProps {
  openInputSelection: typeof openInputSelection;
  closeInputSelection: typeof closeInputSelection;
  loadChildSelection: typeof loadChildSelection;
}
const mapDispatchToProps: DispatchProps = {
  openInputSelection,
  closeInputSelection,
  loadChildSelection
};

interface StateProps {
  isSelectionInputOpen: boolean;
}

function mapStateToProps(state: ReduxState, props: OwnProps): StateProps {
  const { uiStore } = state;

  return {
    isSelectionInputOpen: uiStore.isSelectionInputOpen
  };
}

const intlNavMenuHOC = injectIntl(NavMenu);
const styledNavMenuThunk = withStyles(styles, { withTheme: true });
const styledNavMenuHOC = styledNavMenuThunk(intlNavMenuHOC);

// Nifty three parameter generic connect that indicates where all this components props come from
// The first one describes the props that mapStateToProps grabs from redux state
// The second one describes the props that magical mapDispatchToProps grabs from the action creators
// The third one is the public interface that describes props expected from a parent component
export default connect<StateProps, DispatchProps, OwnProps, ReduxState>(mapStateToProps, mapDispatchToProps)<any>
                        (styledNavMenuThunk(styledNavMenuHOC));
