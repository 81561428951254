import * as React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { InjectedIntl }  from 'react-intl';
// import InjectedIntl = ReactIntl.InjectedIntl;


interface ErrorDialogProps {
  isOpen: boolean;
  message: string;
  details: string;
  intl: InjectedIntl;
  handleClose: () => void;
}

/**
 * An error dialog used to display any errors that occur.
 * A messageKey should be provided to be used as a key to an internationalized message.
 */
export class ErrorDialog extends React.Component<ErrorDialogProps> {
  public render () {
    const { isOpen, handleClose, message, details, intl } = this.props;

    return (
    <div>
      <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">{intl.formatMessage({ "id": "error_title" })}</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-dialog-description">
            { message }
              <hr/>
            { details }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {intl.formatMessage({ "id": "ok_label" })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }

}


