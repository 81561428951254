import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import registerServiceWorker from './registerServiceWorker';

import CssBaseline from "@material-ui/core/CssBaseline";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {connectRouter, routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from "history";
import {IntlProvider} from "react-intl";
import {Provider} from "react-redux";
import {applyMiddleware, compose, createStore} from "redux";
import {createLogger} from "redux-logger";
import {getLocaleMessages, getSupportedLocale} from "./locales/locales";


import thunk from "redux-thunk";
import {theme} from "./components/theme";
import rootReducer from "./store/index";
import {ReduxState} from "./store/ReduxState";
import {wrapFetch} from "./util/wrappedFetch";

const history = createBrowserHistory();
const middlewares = [routerMiddleware(history), thunk];

if (process.env.NODE_ENV === "development") {
  const logger = createLogger({
    "collapsed": true
  });

  middlewares.push(logger);
}


const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
    connectRouter(history)(rootReducer),
    composeEnhancer(
        applyMiddleware(
            ...middlewares
        ),
    ),
);

// Wrap the fetch up so auto token handling is taken care of
wrapFetch(store.getState as unknown as () => ReduxState, store.dispatch);

const render = () => {
  ReactDOM.render(
      <React.Fragment>
        <CssBaseline/>
          <Provider store={store}>
            <IntlProvider locale={getSupportedLocale()} key={getSupportedLocale()}
                          messages={getLocaleMessages(getSupportedLocale())}>
              <MuiThemeProvider theme={theme}>
                <App history={history}/>
              </MuiThemeProvider>
            </IntlProvider>
          </Provider>
      </React.Fragment>,
      document.getElementById("root"),
  );
};
render();

registerServiceWorker();
