import {User} from "@uplan/common";
import fetch from "cross-fetch";
import {Dispatch} from "redux";
import { wrappedFetch } from "../../util/wrappedFetch";
import {errorAction} from "../ui/actions";
import {
  AUTHENTICATION_ERROR,
  AUTHENTICATION_REQUEST,
  AUTHENTICATION_RESPONSE,
  REVOKE_TOKEN_REQUEST,
  REVOKE_TOKEN_RESPONSE,
  TOKEN_REQUEST, TOKEN_RESPONSE,
} from "./types";
import {removeAuthenticatedUser, setAuthenticatedUser} from "../../util/auth/Authorization";
import { config } from "../../config";

// Seems like having endpoint constants defined in a common constant file
// would be a good idea
const AUTH_ENDPOINT = `${config.apiBase}authenticate`;
const TOKEN_ENDPOINT = `${config.apiBase}getToken`;
const REVOKE_TOKEN_ENDPOINT = `${config.apiBase}revokeToken`;

export const authenticate = (appName: string) => async (dispatch: Dispatch) => {
  try {
    
    // Seems like typesafe actions would be a good idea.
    dispatch({type: AUTHENTICATION_REQUEST});

    // The wrapped fetch isn't needed because its already known that the user isn't
    // authenticated
    console.log("Strted");
    console.log(AUTH_ENDPOINT);
    console.log(appName);
    const authResponse = await fetch(`${AUTH_ENDPOINT}?appName=${appName}`);
    console.log(authResponse);
    const auth = await authResponse.json();
    console.log(auth);
    console.log(auth.message);
    if (auth.message) {
      dispatch({
        type: AUTHENTICATION_ERROR,
     });
      errorAction("error_authenticating", auth.message)(dispatch);
    } else {
     dispatch({
      type: AUTHENTICATION_RESPONSE,
        authenticationCode: auth.authenticationCode,
      });
      ////setAuthenticatedUser1();
      window.location.href = auth.redirectUrl;
      ////window.location.href = "http://bucket-7034659-utrack-webapp-stg.s3.amazonaws.com/utrack/cellLinker";
    }
  } catch (e) {
    console.log(e);
    console.log(e.message);
    setTimeout(() => {
      removeAuthenticatedUser();
      window.location.href = "/";
    }, 5000);
    dispatch({
      type: AUTHENTICATION_ERROR,
    });
    errorAction("error_authenticating", e.message)(dispatch);
  }
};

export const getToken = (appName: string, authenticationCode: string) => async (dispatch: Dispatch) => {
  try {
    // Seems like typesafe actions would be a good idea.
    dispatch({type: TOKEN_REQUEST});

    const authResponse = await fetch(`${TOKEN_ENDPOINT}?authenticationCode=${authenticationCode}&appName=${appName}`);
    if (authResponse.status > 299) {
      const auth: any = authResponse.json();
      dispatch({
        type: AUTHENTICATION_ERROR,
      });
      errorAction("error_getting_token", auth.message)(dispatch);
    } else {
      const user: User = await authResponse.json();
      setAuthenticatedUser(user);
      dispatch({
        type: TOKEN_RESPONSE,
        user,
      });
    }
  } catch (e) {
    removeAuthenticatedUser();
    dispatch({
      type: AUTHENTICATION_ERROR,
    });
    errorAction("", e.message)(dispatch);
  }
};

export const revokeToken = () => async (dispatch: Dispatch) => {
  try {
    // Seems like typesafe actions would be a good idea.
    dispatch({type: REVOKE_TOKEN_REQUEST});

    const authResponse = await wrappedFetch(REVOKE_TOKEN_ENDPOINT);
    const auth = await authResponse.json();
    removeAuthenticatedUser();
    if (auth.message) {
      dispatch({
        type: AUTHENTICATION_ERROR,
      });
    } else {
      dispatch({
        type: REVOKE_TOKEN_RESPONSE,
      });
    }
  } catch (e) {
    removeAuthenticatedUser();
    dispatch({
      type: AUTHENTICATION_ERROR,
    });
  }
};
