import { addLocaleData } from "react-intl";
import * as intlEN from "react-intl/locale-data/en";
import * as intlES from "react-intl/locale-data/es";
import { messages as en_messages } from "./en";
import { messages as es_messages } from "./es";

const locales = {
  "en": {
    "locale": "en",
    "messages": en_messages
  },
  "es": {
    "locale": "es",
    "messages": es_messages
  }

};

addLocaleData([...intlEN, ...intlES]);

/**
 * Returns English if the browser locale is anything other than English or Spanish.
 */
export const getSupportedLocale = (): string => {
  if (navigator.language === "en" || navigator.language === "es") {
    return navigator.language;
  } else {
    return "en";
  }
};

export const getLocaleMessages = (locale: string) => {
  if (locales[locale]) {
    return locales[locale].messages;
  } else {
    return en_messages; // Default locale
  }
};

export default locales;
